import { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { StaffLabelTextS } from "@doktor-se/bones-ui"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { useAppDispatch, useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { QueueConversation } from "types"

import { BooleanCell, DateTimePillCell, NameCell } from "components/v2/conversationList/Cells"
import ConfirmDialog from "components/v2/conversationList/components/ConfirmDialog/ConfirmDialog"
import EmptyListRow from "components/v2/conversationList/EmptyListRow"
import ListHeader from "components/v2/conversationList/ListHeader"
import ListRow, { Column } from "components/v2/conversationList/ListRow"

import { claimConversation } from "../../../../api"

interface ActiveConversationListProps {
  title: string
  conversations: QueueConversation[]
}

export interface ConversationListItem {
  conversation: QueueConversation
  values: Column[]
}

const ActiveConversationList = ({ title, conversations }: ActiveConversationListProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const categories = useAppSelector(categorySelectors.selectEntities)
  const [claimDialogOpen, setClaimDialogOpen] = useState(false)
  const [selectedConversation, setSelectedConversation] = useState<QueueConversation>()
  const openClaimConversationDialog = (item: ConversationListItem) => {
    setSelectedConversation(item.conversation)
    setClaimDialogOpen(true)
  }
  const columns = [
    {
      name: intl.formatMessage({ id: "label.overview.pnr" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.name" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.reason_for_visit" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.errand_started" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.listed" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medical_consent" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medication_consent" })
    }
  ]

  const mapConversationListItem = useCallback(
    (conv: QueueConversation): ConversationListItem => {
      const isChildConversation = conv.profile?.type === "child"
      return {
        conversation: conv,
        values: [
          {
            key: "pnr",
            content: isChildConversation
              ? formatPnr(conv.profile!.data!.securityNumber, conv.metadata?.region)
              : formatPnr(conv.account?.pnr, conv.metadata?.region)
          },
          {
            key: "name",
            content: (
              <NameCell
                name={isChildConversation ? conv.profile!.name! : conv.account.displayName!}
                isChild={isChildConversation}
              />
            )
          },
          {
            key: "category",
            content: categories[conv.categoryId].name
          },
          {
            key: "start",
            content: <DateTimePillCell value={conv.queuePriority} />
          },
          {
            key: "listed",
            content: <BooleanCell value={!!conv.customerMetadata?.listedAtClinicId} />
          },
          {
            key: "recordConsent",
            content: <BooleanCell value={true} />
          },
          {
            key: "medicationConsent",
            content: <BooleanCell value={true} />
          }
        ]
      }
    },
    [categories]
  )
  const list = useMemo(
    () => conversations.map(conv => mapConversationListItem(conv)),
    [conversations, mapConversationListItem]
  )

  return (
    <>
      <StaffLabelTextS style={{ marginBottom: "16px" }}>{title}</StaffLabelTextS>
      <ListHeader columns={columns} />
      {list.length ? (
        list.map(l => <ListRow column={l.values} onClick={() => openClaimConversationDialog(l)} />)
      ) : (
        <EmptyListRow>{intl.formatMessage({ id: "label.overview.queue_empty" })}</EmptyListRow>
      )}
      <ConfirmDialog
        isOpen={claimDialogOpen}
        title={intl.formatMessage({ id: "conversation.claim.confirm.dialog.title" })}
        message={intl.formatMessage({ id: "conversation.claim.confirm.dialog.message" })}
        conversation={selectedConversation}
        onConfirm={() => selectedConversation && dispatch(claimConversation(selectedConversation, navigate))}
        onCancel={() => setClaimDialogOpen(false)}
        confirmText={intl.formatMessage({ id: "conversation.claim.confirm.dialog.button" })}
      />
    </>
  )
}

export default ActiveConversationList
