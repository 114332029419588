import { StaffBodyTextXS } from "@doktor-se/bones-ui"
import { AssignedConversation, Profile } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"

import SetProfile from "./SetProfile"

import styles from "./PatientName.module.scss"

interface PatientNameProps {
  displayName: string
  conversation: AssignedConversation
  prevConversationId?: string
  profile?: Profile
}

const PatientName = ({ conversation, prevConversationId, displayName, profile }: PatientNameProps) => {
  if (featureFlags.has("add_profile")) {
    return (
      <SetProfile
        conversation={conversation}
        patientName={!!profile ? profile.name : displayName}
        prevConversationId={prevConversationId}
        type={profile ? "child" : undefined}
      />
    )
  }

  return (
    <div className={styles.row}>
      <StaffBodyTextXS margin={{ right: "20px" }} className={styles.name}>
        {displayName}
      </StaffBodyTextXS>
    </div>
  )
}

export default PatientName
