import { ICONS } from "@doktor-se/bones-ui"

import styles from "./Header.module.scss"

interface NavigationHeaderProps {
  collapsed: boolean
  toggleCollapse: () => void
}

const NavigationHeader = ({ collapsed, toggleCollapse }: NavigationHeaderProps) => {
  const Icon = collapsed ? ICONS.ChevronRight : ICONS.ChevronLeft
  const iconSize = collapsed ? 20 : 12
  return (
    <div className={styles.header}>
      <ICONS.CarealotLogo width={iconSize} height={iconSize} fill="white" />
      {!collapsed && "Carealot"}
      <Icon onClick={toggleCollapse} className={styles.toggle} />
    </div>
  )
}

export default NavigationHeader
