import { useMemo } from "react"

import { StaffLabelTextXXS } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"

import styles from "./UserInfo.module.scss"

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getInitials = (name?: string) =>
  name
    ?.split(" ")
    .map(w => w[0].toUpperCase())
    .join("")

interface NavigationUserInfoProps {
  collapsed: boolean
}

const NavigationUserInfo = ({ collapsed }: NavigationUserInfoProps) => {
  const { user, defaultRole } = useAppSelector(state => state.auth)
  const clinics = useAppSelector(clinicSelectors.selectAll)

  const userClinic = useMemo(() => {
    if (user?.data?.clinic) {
      return clinics.find(c => c.id === user?.data?.clinic)?.name
    }
    return undefined
  }, [clinics, user?.data?.clinic])

  if (collapsed) {
    return (
      <div className={styles.collapsed}>
        <StaffLabelTextXXS>{getInitials(user?.displayName)}</StaffLabelTextXXS>
      </div>
    )
  }

  return (
    <div className={styles.expanded}>
      <span>{user?.displayName}</span>
      {defaultRole && <span>{capitalize(defaultRole)}</span>}
      {userClinic && <span>{userClinic}</span>}
    </div>
  )
}

export default NavigationUserInfo
