import React, { useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { CopyToClipboard, Radio, RadioGroup, TextArea } from "@doktor-se/bones-ui"
import { FieldType, JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"

import { saveJournalNote } from "api"
import { logAmplitudeEvent } from "lib/amplitude/amplitude"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import { stringArrayEquals } from "../../utils"
import SelectCodes from "../codes/SelectCodes"

import styles from "./AutoSaveForm.module.scss"

interface JournalFormData {
  sections: JournalSection[]
  codes?: string[]
}

interface AutoSaveFormProps {
  categoryId?: number
  conversationId: string
  initialJournalValues: JournalFormData
}

const yesNoOptions = [
  {
    value: "Ja",
    translationKey: "Ja"
  },
  {
    value: "Nej",
    translationKey: "Nej"
  }
]

const smsOptions = [
  {
    value: "Ja",
    translationKey: "Ja"
  },
  {
    value: "Nej",
    translationKey: "Nej"
  },
  {
    value: "Ej aktuellt/ej tillfrågad",
    translationKey: "Ej aktuellt/ej tillfrågad"
  }
]
const contactOptions = [
  {
    value: "Telefon",
    translationKey: "Telefon"
  },
  {
    value: "Chatt",
    translationKey: "Chatt"
  },
  {
    value: "Videosamtal",
    translationKey: "Videosamtal"
  }
]

const AutoSaveForm = ({ conversationId, categoryId, initialJournalValues }: AutoSaveFormProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [formData, setFormData] = useState<JournalFormData>(initialJournalValues)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const saveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const diagnosisCodes = useAppSelector(state => state.conversations.webdocDiagnosisCodes)

  const onCopy = () => {
    logAmplitudeEvent("carealot.chat_journal.copy_note_pressed", {
      category_id: categoryId
    })
  }

  const updateSectionValue = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      sections: prev.sections.map(section => (section.title === key ? { ...section, body: value } : section))
    }))
    setHasUnsavedChanges(true)
  }

  const updateCodesValue = (codes: string[]) => {
    setFormData(prev => ({
      ...prev,
      codes
    }))
    setHasUnsavedChanges(true)
  }

  useEffect(() => {
    setFormData({ ...initialJournalValues, codes: initialJournalValues.codes || [] })
    setHasUnsavedChanges(false)
  }, [initialJournalValues])

  const saveData = useCallback(() => {
    if (!hasUnsavedChanges || isSaving) return
    setIsSaving(true)
    dispatch(saveJournalNote(conversationId, formData.sections, false, formData.codes))
    setIsSaving(false)
    setHasUnsavedChanges(false)
  }, [conversationId, dispatch, formData.codes, formData.sections, hasUnsavedChanges, isSaving])

  useEffect(() => {
    if (hasUnsavedChanges) {
      if (saveTimeout.current) clearTimeout(saveTimeout.current)
      saveTimeout.current = setTimeout(() => {
        saveData()
      }, 2000)
    }
    return () => {
      if (saveTimeout.current) clearTimeout(saveTimeout.current)
    }
  }, [hasUnsavedChanges, formData, saveData])

  return (
    <>
      {formData.sections.map((item, index) => (
        <div className={styles.journalSection} key={index}>
          <div className={styles.header}>
            <div key={item.title + index} className={styles.sectionTitle}>
              {intl.formatMessage({ id: item.title })}
            </div>
            {item.body !== initialJournalValues.sections.find(section => item.title === section.title)?.body ? (
              <div key={item.title + "_i_" + index} className={styles.edited}>
                {intl.formatMessage({ id: "journal.edited.indicator" })}
              </div>
            ) : undefined}
            <CopyToClipboard
              onCopy={onCopy}
              copyText={item.body || ""}
              confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
            />
          </div>
          <div className={styles.fieldWrapper}>
            {item.type === FieldType.checkbox ? (
              <div className={styles.radioWrapper}>
                {item.title === "Samtycke skicka SMS" ? (
                  <RadioGroup
                    id={item.title}
                    label=""
                    aria-label="CheckBox section"
                    aria-labelledby=""
                    value={item.body || "Nej"}
                    orientation={"horizontal"}
                    onChange={value => {
                      updateSectionValue(item.title, "" + value)
                    }}>
                    {smsOptions.map(option => (
                      <Radio key={item.title + "_" + option.value} value={option.value}>
                        {option.translationKey}
                      </Radio>
                    ))}
                  </RadioGroup>
                ) : (
                  <RadioGroup
                    id={item.title}
                    label=""
                    aria-label="CheckBox section"
                    aria-labelledby=""
                    value={item.body || "Nej"}
                    orientation={"horizontal"}
                    onChange={value => {
                      updateSectionValue(item.title, "" + value)
                    }}>
                    {yesNoOptions.map(option => (
                      <Radio key={item.title + "_" + option.value} value={option.value}>
                        {option.translationKey}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              </div>
            ) : item.type === FieldType.radio_btn ? (
              <div>
                <RadioGroup
                  id={item.title}
                  label=""
                  aria-label="Radio section"
                  aria-labelledby=""
                  value={item.body}
                  onChange={value => {
                    updateSectionValue(item.title, value)
                  }}>
                  {contactOptions.map(option => (
                    <Radio key={item.title + "_" + option.value} value={option.value}>
                      {option.translationKey}
                    </Radio>
                  ))}
                </RadioGroup>
              </div>
            ) : (
              <TextArea
                variant={"onSurface"}
                noVisualLabel={true}
                label={"nolabel"}
                noBorder
                fullWidth={true}
                onChange={(value: string) => updateSectionValue(item.title, value)}
                value={item.body}
              />
            )}
          </div>
        </div>
      ))}
      {!stringArrayEquals(formData.codes || [], initialJournalValues.codes || []) && (
        <div className={classNames([styles.edited, styles.editedCodes])}>
          {intl.formatMessage({ id: "journal.edited.indicator" })}
        </div>
      )}
      {diagnosisCodes && (
        <SelectCodes diagnosisCodes={diagnosisCodes} codesValue={formData.codes} onCodesChange={updateCodesValue} />
      )}
    </>
  )
}

export default AutoSaveForm
