import { StaffLabelTextM } from "@doktor-se/bones-ui"

import styles from "./EmptyListRow.module.scss"

interface EmptyListRowProps {
  children: string
}

const EmptyListRow = ({ children }: EmptyListRowProps) => (
  <div className={styles.emptyRow}>
    <StaffLabelTextM>{children}</StaffLabelTextM>
  </div>
)

export default EmptyListRow
