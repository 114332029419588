import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { fetchPatientsClinics } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import ConversationContainer from "components/v2/conversationContainer/ConversationContainer"

const DashboardPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const conversation = useAppSelector(selectConversation)

  useEffect(() => {
    if (!conversation) {
      navigate("/overview")
    }
  }, [conversation, navigate])

  useEffect(() => {
    if (conversation?.id && conversation?.patientId) {
      dispatch(fetchPatientsClinics(conversation?.patientId))
    }
  }, [conversation?.id, conversation?.patientId, dispatch])

  return (
    <>
      <ConversationContainer />
    </>
  )
}

export default DashboardPage
