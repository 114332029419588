import { useCallback, useEffect, useMemo } from "react"

import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { createLoadingSelector, fetchSelectedQueueOutline } from "api"
import { defaultPageSizeForQueue } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectSelectedQueue } from "reducers/queue/queue.reducer"

import ActiveConversationList from "./ActiveConversationList"
import PostponedConversationList from "./PostponedConversationList"

import styles from "./QueueConversationList.module.scss"

interface QueueConversationListProps {
  selectedQueue?: Queue
  selectedQueueKey: string
}

const LoadingSelector = createLoadingSelector(["QUEUE"])

const QueueConversationList = ({ selectedQueue }: QueueConversationListProps) => {
  const dispatch = useAppDispatch()
  const appLanguage = useAppSelector(state => state.app.language)
  const loader = useAppSelector(state => LoadingSelector(state))
  const { active, postponed } = useAppSelector(selectSelectedQueue)

  const fetchQueue = useCallback(() => {
    if (selectedQueue) {
      dispatch(fetchSelectedQueueOutline(selectedQueue?.queueKey, defaultPageSizeForQueue))
    }
  }, [dispatch, selectedQueue])

  useEffect(() => {
    fetchQueue()
  }, [selectedQueue, dispatch, fetchQueue])

  const queueName = useMemo(() => selectedQueue?.translations[appLanguage], [selectedQueue, appLanguage])

  if (loader) {
    return (
      <div style={{ position: "relative", minHeight: "100px" }}>
        <Loader type="small" />
      </div>
    )
  }

  return (
    <>
      <div className={styles.listContainer}>
        <ActiveConversationList title={queueName!} conversations={active} />
      </div>
      {!!postponed.length && (
        <div className={styles.listContainer}>
          <PostponedConversationList conversations={postponed} />
        </div>
      )}
    </>
  )
}

export default QueueConversationList
