import React, { RefObject } from "react"

import classnames from "classnames"

import styles from "./MenuItem.module.scss"

interface MenuItemProps {
  children?: JSX.Element
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  label: string
  active?: boolean
  onClick: () => void
  menuItemRef?: RefObject<HTMLButtonElement>
  additionalButtonStyles?: string
  collapsed: boolean
}

const MenuItem = ({
  Icon,
  label,
  children,
  active = false,
  onClick,
  menuItemRef,
  additionalButtonStyles = "",
  collapsed
}: MenuItemProps) => (
  <div className={classnames({ [styles.menuItemWrapperActive]: active })}>
    <button ref={menuItemRef} className={classnames(styles.menuItem, additionalButtonStyles)} onClick={onClick}>
      <div className={styles.iconWrapper}>
        <Icon />
      </div>
      {!collapsed && label}
    </button>
    {!collapsed && <>{active && children && <div className={styles.subMenuItems}>{children}</div>}</>}
  </div>
)

export default MenuItem
