import dayjs from "dayjs"
import humps from "humps"
import { datetime, Frequency, RRule } from "rrule"

import { Account, BookingType, Conversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import {
  addCalendarBooking,
  addCalendarBookings,
  addPersonalBooking,
  addSlots,
  Booking,
  BookingState,
  clearDraftBookingPatient,
  loadBookingTypes,
  removeBooking,
  removeBookings,
  removeSlots,
  setPersonalBookings,
  setSlots,
  Slot,
  updateBooking as updateBookingAction,
  updateBookingsAssignee
} from "reducers/booking"
import { IncomingError } from "reducers/error"

import { SlotHistoryEvent } from "../../components/booking/slot/history/SlotHistory"
import { RecurrenceRule } from "../../components/booking/slot/SlotRecurrence"

export type KindNone = "none" | undefined

export const fetchMeetingKinds = (): Thunk => async (dispatch, getState) => {
  try {
    const state = getState()
    const clinicParameter = state.auth.user?.data?.clinic ? "?clinic_id=" + state.auth.user?.data?.clinic : ""
    const response: { kinds: BookingType[] } = await dispatch(
      apiFetch(`/calendar/kinds${clinicParameter}`, { loader: "BOOKING" })
    )
    dispatch(loadBookingTypes(response.kinds))
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

export const reloadCalendarBookings = (): Thunk => (dispatch, getState) => {
  const state = getState()
  const staff_filter = state.booking.calendar.filters.staff || state.auth.user?.id
  const range = state.booking.calendar.range
  const kind_ids = state.booking.calendar.filters.kind?.filter(i => i.selected).map(i => i.id)
  const kind_none = checkKindsAvailability(state.booking)
  if (range) {
    const params = {
      start: range.from,
      end: range.to,
      staff_id: staff_filter,
      ...(kind_ids && kind_ids.length > 0 && { kind_ids: kind_ids }),
      ...(kind_none && { kind_none: kind_none })
    }
    dispatch(fetchCalendarBookings(params))
  }
}

export const reloadCalendarSlots = (): Thunk => (dispatch, getState) => {
  const state = getState()
  const staff_filter = state.booking.calendar.filters.staff || state.auth.user?.id
  const range = state.booking.calendar.range
  const kind_ids = state.booking.calendar.filters.kind?.filter(i => i.selected).map(i => i.id)
  const kind_none = checkKindsAvailability(state.booking)
  if (range) {
    const params = {
      start: range.from,
      end: range.to,
      staff_id: staff_filter,
      ...(kind_ids && kind_ids.length > 0 && { kind_ids: kind_ids }),
      ...(kind_none && { kind_none: kind_none })
    }
    dispatch(fetchSlots(params))
  }
}

const checkKindsAvailability = (state: BookingState): KindNone => {
  return state.calendar.filters.kind && state.calendar.filters.kind?.filter(f => f.selected).length === 0
    ? "none"
    : undefined
}

const getQueryParams = (params: {
  start: string
  end: string
  staff_id?: string
  kind_ids?: string[]
  kind_none?: KindNone
}): string[][] => {
  // There is an issue with Fastify to pass list of parameter https://github.com/fastify/fastify/issues/2841
  let arrayParams = [
    ["start", params.start],
    ["end", params.end]
  ]
  if (params.staff_id) arrayParams.push(["staff_filter", params.staff_id])
  params.kind_ids?.forEach(item => arrayParams.push(["kind_ids", item]))
  if (params.kind_none) arrayParams.push(["kind_id", params.kind_none])
  return arrayParams
}

export const fetchSlots =
  (params: { start: string; end: string; staff_id?: string; kind_ids?: string[] }): Thunk =>
  async dispatch => {
    try {
      const queryParams = new URLSearchParams(getQueryParams(params)).toString()
      const response: { slots: Slot[] } = await dispatch(
        apiFetch(`/calendar/slots?${queryParams}`, { loader: "BOOKING" })
      )
      dispatch(setSlots(response.slots))
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/calendar/slots?<params>" }))
    }
  }

export const fetchPersonalBookings = (): Thunk => async (dispatch, getState) => {
  try {
    const start = dayjs().subtract(1, "day").toISOString()
    const staff_filter = getState().auth.user?.id!
    const queryParams = new URLSearchParams({ staff_filter, start }).toString()
    const response: { bookings: Booking[] } = await dispatch(
      apiFetch(`/calendar/bookings/staff?${queryParams}`, { loader: "BOOKING" })
    )
    dispatch(setPersonalBookings(response.bookings))
  } catch (error: any) {
    dispatch(handleErrors({ error, customContext: "/calendar/bookings/staff?<params>" }))
  }
}

export const fetchCalendarBookings =
  (params: { start: string; end: string; staff_id?: string; kind_ids?: string[] }): Thunk =>
  async dispatch => {
    try {
      const queryParams = new URLSearchParams(getQueryParams(params)).toString()
      const response: { bookings: Booking[] } = await dispatch(
        apiFetch(`/calendar/bookings/staff?${queryParams}`, { loader: "BOOKING" })
      )
      dispatch(addCalendarBookings(response.bookings))
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/calendar/bookings/staff?<params>" }))
    }
  }

const RRuleMapping = { weekly: Frequency.WEEKLY, daily: Frequency.DAILY }

export const createSlots =
  (slots: Slot[], recurrenceRule?: RecurrenceRule): Thunk<Promise<true | undefined>> =>
  async dispatch => {
    try {
      let rule
      if (recurrenceRule && recurrenceRule.repeatOption !== "none") {
        const { repeatUntil } = recurrenceRule
        const until = datetime(repeatUntil!.getFullYear(), repeatUntil!.getMonth() + 1, repeatUntil!.getDate())
        rule = new RRule({
          freq: RRuleMapping[recurrenceRule.repeatOption],
          byweekday: recurrenceRule.repeatByWeekday,
          until
        }).toString()
      }
      const response: { slots: Slot[] } = await dispatch(
        apiFetch(`/calendar/slots`, {
          loader: "BOOKING",
          method: "POST",
          body: JSON.stringify({
            slots: slots.map(slot => ({
              staff_id: slot.staffId,
              kind_id: slot.kindIds[0],
              start: slot.start,
              note: slot.note
            })),
            recurrenceRule: rule
          })
        })
      )
      dispatch(addSlots(response.slots))
      return true
    } catch (error: any) {
      dispatch(handleBookingErrors(error))
    }
  }

const handleBookingErrors =
  (error: IncomingError, customContext?: string): Thunk =>
  dispatch => {
    const errorData: { error: IncomingError; customMessage?: string; customContext?: string } = {
      error,
      customContext: customContext || undefined
    }

    if (error?.details?.code === "error.calendar.booking_overlapping") {
      errorData.customMessage = "calendar.error.bookings.overlapping"
    }

    if (error?.details?.code === "error.calendar.invalid_recurrence_rule") {
      errorData.customMessage = "calendar.error.slots.invalid_recurrence_rule"
    }
    if (error?.details?.code === "error.calendar.invalid_recurrence_rule_to_long_period") {
      errorData.customMessage = "calendar.error.slots.invalid_recurrence_rule_to_long_period"
    }
    const overlappingSlotCodes = ["error.calendar.no_concurrent_slots", "error.calendar.slot_overlapping"]

    if (error?.details?.code && overlappingSlotCodes.includes(error?.details?.code)) {
      errorData.customMessage = "calendar.error.slots.overlapping"
    }

    if (error?.details?.code === "error.calendar.no_concurrent_bookings") {
      errorData.customMessage = "calendar.error.bookings.staff.concurrent"
    }

    if (error?.details?.code === "error.calendar.event_unassigned_not_permitted") {
      errorData.customMessage = "calendar.error.unassigned.not.permitted"
    }

    dispatch(handleErrors(errorData))
  }

export interface BookingParams {
  id: string
  staff_id?: string
  start: string
  end: string
  kind_id?: string
  user_id?: string
  conversation_id?: string
  metadata?: { custom?: "event"; details?: { title?: string; description?: string } }
  profile_id?: string
  premium_health?: boolean
}

export const createBooking =
  (booking: BookingParams): Thunk<Promise<string | undefined>> =>
  async (dispatch, getState) => {
    try {
      const response: Booking = await dispatch(
        apiFetch(`/calendar/bookings/${booking.id}`, {
          loader: "BOOKING",
          method: "POST",
          body: JSON.stringify({ ...booking, metadata: JSON.stringify(booking.metadata) })
        })
      )
      dispatch(addCalendarBooking(response))
      dispatch(addPersonalBooking({ booking: response, userId: getState().auth.user?.id }))
      dispatch(reloadCalendarSlots())
      return response.id
    } catch (error: any) {
      dispatch(handleBookingErrors(error, "/calendar/bookings/<bookingId>"))
    }
  }

export interface UpdateBookingParams {
  id: string
  staff_id: string
  start: string
  end: string
}

export const updateBooking =
  (booking: UpdateBookingParams): Thunk<Promise<string | undefined>> =>
  async dispatch => {
    try {
      const response: Booking = await dispatch(
        apiFetch(`/calendar/bookings/${booking.id}`, {
          loader: "BOOKING",
          method: "PUT",
          body: JSON.stringify({ ...booking })
        })
      )
      dispatch(updateBookingAction(response))
      dispatch(reloadCalendarSlots())
      return response.id
    } catch (error: any) {
      dispatch(handleBookingErrors(error, "/calendar/bookings/<bookingId>"))
    }
  }

export interface ReassignBookingParams {
  booking_ids: string[]
  staff_id: string
  isInGroupCalendar: boolean
}

export interface ReassignedBookings {
  bookings: Booking[]
}

export const reassignBooking =
  (booking: ReassignBookingParams): Thunk<Promise<Booking[] | undefined>> =>
  async (dispatch, getState) => {
    try {
      const response: ReassignedBookings = await dispatch(
        apiFetch(`/calendar/bookings/reassign`, {
          loader: "BOOKING",
          method: "POST",
          body: JSON.stringify({ ...booking })
        })
      )
      // if we are operating in group calendar we just update the booking
      // if we are working in personal calendar or "UNASSIGNED" view we need to remove the booking
      const bookingIds = response.bookings.map(b => b.id)
      if (
        !booking.isInGroupCalendar ||
        (getState().booking.calendar.filters.staff === "UNASSIGNED" && booking.staff_id !== "")
      ) {
        dispatch(removeBookings(bookingIds))
      } else {
        const actionPayload = {
          bookingIds,
          staffId: booking.staff_id
        }
        dispatch(updateBookingsAssignee(actionPayload))
      }

      return response.bookings
    } catch (error: any) {
      dispatch(handleBookingErrors(error, "/calendar/bookings/reassign"))
    }
  }

export interface ReassignSlotsParams {
  slot_ids: string[]
  staff_id: string | undefined
  isInGroupCalendar: boolean
}

export interface ReassignedSlots {
  slots: Slot[]
}

export const reassignSlots =
  (params: ReassignSlotsParams): Thunk =>
  async dispatch => {
    try {
      const response: ReassignedSlots = await dispatch(
        apiFetch(`/calendar/slots/reassign`, {
          loader: "BOOKING",
          method: "POST",
          body: JSON.stringify({ ...params })
        })
      )

      // if we are operating in group calendar we just update the slots
      // if we are working in personal calendar we need to remove the slots
      if (!params.isInGroupCalendar) {
        dispatch(removeSlots(response.slots.map(({ id }: Slot) => id)))
      } else {
        dispatch(setSlots(response.slots))
      }
    } catch (error: any) {
      dispatch(handleBookingErrors(error, "/calendar/slots/reassign"))
    }
  }

export interface UpdateSlotParams {
  id: string
  note?: string
}

export const updateSlot =
  (params: UpdateSlotParams): Thunk =>
  async dispatch => {
    try {
      const response: Slot = await dispatch(
        apiFetch(`/calendar/slots/${params.id}`, {
          loader: "BOOKING",
          method: "PUT",
          body: JSON.stringify({ ...params })
        })
      )

      dispatch(setSlots([response]))
    } catch (error: any) {
      dispatch(handleBookingErrors(error, "/calendar/slots/<slotId>"))
    }
  }

export const searchPatientsForBooking =
  (params: { query?: string; pnr?: string }): Thunk<Promise<Account[] | undefined>> =>
  async dispatch => {
    try {
      const queryParams = new URLSearchParams(params).toString()
      const response: Account[] = await dispatch(
        apiFetch(`/patients/search?${queryParams}`, { loader: "BOOKING_PATIENT_SEARCH" })
      )
      if (!!response.length) {
        return response
      } else {
        dispatch(clearDraftBookingPatient())
      }
    } catch (error: any) {
      dispatch(handleErrors({ error, api: "searchPatientForBooking", customContext: "/patients/search?<params>" }))
      dispatch(clearDraftBookingPatient())
    }
  }

export const deleteSlots =
  (slotsIds: string[]): Thunk<Promise<true | undefined>> =>
  async dispatch => {
    try {
      const { failedSlots }: { failedSlots?: string[] } = await dispatch(
        apiFetch("/calendar/slots", {
          loader: "BOOKING",
          method: "DELETE",
          body: JSON.stringify({ slot_ids: slotsIds })
        })
      )

      dispatch(removeSlots(slotsIds.filter(id => !failedSlots?.includes(id))))

      if (!!failedSlots && failedSlots.length > 0) {
        dispatch(
          handleErrors({
            error: new Error(`Some slots could not be deleted: ${failedSlots.map(f => `${f} `)}`),
            customMessage: "calendar.delete.slots.failed"
          })
        )
      }

      return true
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

const deleteBookingApi = (bookingId: string) =>
  apiFetch(`/calendar/bookings/${bookingId}/cancellation/staff`, {
    loader: "BOOKING",
    method: "POST"
  })

export const deleteBooking =
  (bookingId: string): Thunk<Promise<true | undefined>> =>
  async dispatch => {
    try {
      const response: true | undefined = await dispatch(deleteBookingApi(bookingId))

      if (response) {
        dispatch(removeBooking(bookingId))
        dispatch(reloadCalendarSlots())
      }

      return true
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/calendar/bookings/<bookingId>/cancellation" }))
    }
  }

export const deleteBookings =
  (bookingIds: string[]): Thunk =>
  async dispatch => {
    const calls = await Promise.allSettled(
      bookingIds.map(bookingId => dispatch(deleteBookingApi(bookingId)).then(() => removeBooking(bookingId)))
    )

    const rejectedCalls = calls.filter(call => call.status === "rejected").length

    if (rejectedCalls > 0) {
      const error = new Error(`Some bookings could not be deleted`) as IncomingError
      error.details = {
        code: "error.bookings.delete.failed"
      }
      dispatch(
        handleErrors({
          error,
          customMessage: "calendar.delete.bookings.failed"
        })
      )
    }

    dispatch(reloadCalendarSlots())
  }

const parseConversation = (conversation: any): Conversation => {
  return humps.camelizeKeys({
    ...conversation
  }) as Conversation
}

export const fetchConversationForBooking =
  (conversationId: string): Thunk<Promise<Conversation | null>> =>
  async dispatch => {
    try {
      const response = await dispatch(apiFetch(`/conversations/${conversationId}`))
      return parseConversation(response.conversation)
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>" }))
      return null
    }
  }
export const fetchSlotHistory =
  (slotId: string): Thunk<Promise<SlotHistoryEvent[] | undefined>> =>
  async dispatch => {
    try {
      const response: { events: SlotHistoryEvent[] } = await dispatch(
        apiFetch(`/calendar/slots/${slotId}/history`, { method: "GET", loader: "SLOT" })
      )
      return response.events
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }
