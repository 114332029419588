import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextXS, StaffLabelTextL } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { ageFromPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { updateCategory } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { genderFromPnr } from "lib/utils"
import { categorySelectors } from "reducers/categories"

import CategoriesModal from "components/CategoriesModal/CategoriesModal"
import CategoryBadge from "components/CategoryBadge/CategoryBadge"
import ListingInformation from "pages/dashboard/components/ConversationControls/components/FeedHeader/ListingInformation/ListingInformation"

import styles from "./PatientHeader.module.scss"

interface PatientHeaderProps {
  conversation: AssignedConversation
}

const PatientHeader = ({ conversation }: PatientHeaderProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const { profile, categoryId, id, account } = conversation

  const categories = useAppSelector(categorySelectors.selectAll)
  const category = useMemo(() => categories.find(category => category.id === categoryId), [categories, categoryId])

  const ageAndGenderString = useMemo(() => {
    const pnr = !!profile ? profile.data.securityNumber : account.pnr
    if (pnr) {
      const age = ageFromPnr(pnr)
      const ageString = intl.formatMessage({ id: "label.patient_header.age" }, { value: age })
      const gender = genderFromPnr(pnr)
      const genderString = intl.formatMessage({ id: `label.patient_header.gender.${gender}` })
      return (
        <div className={styles.ageAndGender}>
          <StaffBodyTextXS style={{ marginRight: "16px" }}>{ageString}</StaffBodyTextXS>
          <StaffBodyTextXS>{genderString}</StaffBodyTextXS>
        </div>
      )
    }

    return ""
  }, [account.pnr, intl, profile])

  return (
    <div className={styles.container}>
      <div>
        <button
          data-testid="category-button"
          className={styles.categoryButton}
          onClick={() => setShowModal(!showModal)}>
          <CategoryBadge size="huge" category={category} type="general" />
          <div className={styles.penIconWrapper}>
            <ICONS.EditPen width={14} height={14} className={styles.penIcon} />
          </div>
        </button>
      </div>
      <div className={styles.detailsContainer}>
        <div>
          <div className={styles.nameContainer}>
            {profile && <ICONS.Child className={styles.childIcon} />}
            <StaffLabelTextL className={styles.name}>{profile ? profile.name : account.displayName}</StaffLabelTextL>
          </div>
          {ageAndGenderString}
        </div>
        <ListingInformation />
      </div>
      <CategoriesModal
        selectedCategory={category}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        updateCategory={categoryId => dispatch(updateCategory(categoryId, id))}
      />
    </div>
  )
}

export default PatientHeader
