import React from "react"

import classNames from "classnames"

import styles from "./ListRow.module.scss"

export interface Column {
  key: string
  content: string | JSX.Element | undefined
}

interface ListRowProps {
  column: Column[]
  onClick?: () => void
  notify?: boolean
}

const ListRow = ({ column, notify = false, onClick }: ListRowProps) => {
  return (
    <div
      className={classNames(styles.row, { [styles.notify]: notify })}
      role="button"
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === " ") {
          onClick && onClick()
        }
      }}
      tabIndex={0}>
      {column.map(cell => (
        <div key={cell.key} className={styles.cell}>
          {cell.content}
        </div>
      ))}
    </div>
  )
}

export default ListRow
