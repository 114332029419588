import { useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffLabelTextS } from "@doktor-se/bones-ui"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { QueueConversation } from "types"

import { BooleanCell, DateTimePillCell, NameCell } from "components/v2/conversationList/Cells"
import ListHeader from "components/v2/conversationList/ListHeader"
import ListRow from "components/v2/conversationList/ListRow"

interface PostponedConversationListProps {
  conversations: QueueConversation[]
}

const PostponedConversationList = ({ conversations }: PostponedConversationListProps) => {
  const intl = useIntl()
  const categories = useAppSelector(categorySelectors.selectEntities)

  const columns = [
    {
      name: intl.formatMessage({ id: "label.overview.pnr" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.name" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.reason_for_visit" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.postponed_to" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.listed" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medical_consent" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medication_consent" })
    }
  ]

  const list = useMemo(
    () =>
      conversations.map(conv => {
        const isChildConversation = conv.profile?.type === "child"
        return [
          {
            key: "pnr",
            content: isChildConversation
              ? formatPnr(conv.profile!.data!.securityNumber, conv.metadata?.region)
              : formatPnr(conv.account?.pnr, conv.metadata?.region)
          },
          {
            key: "name",
            content: (
              <NameCell
                name={isChildConversation ? conv.profile!.name! : conv.account.displayName!}
                isChild={isChildConversation}
              />
            )
          },
          {
            key: "category",
            content: categories[conv.categoryId].name
          },
          {
            key: "start",
            content: <DateTimePillCell value={conv.queuePriority} />
          },
          {
            key: "listed",
            content: <BooleanCell value={!!conv.customerMetadata?.listedAtClinicId} />
          },
          {
            key: "recordConsent",
            content: <BooleanCell value={true} />
          },
          {
            key: "medicationConsent",
            content: <BooleanCell value={true} />
          }
        ]
      }),
    [categories, conversations]
  )

  return (
    <>
      <StaffLabelTextS style={{ marginBottom: "16px" }}>Postponed</StaffLabelTextS>
      <ListHeader columns={columns} />
      {list.map(l => (
        <ListRow column={l} />
      ))}
    </>
  )
}

export default PostponedConversationList
