import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"

import { ICONS, SidebarMenu } from "@doktor-se/bones-ui"
import { BookingType, Role } from "@doktor-se/bones-ui/dist/web-shared/types"

import { logout } from "api"
import { featureFlags, showHelpPage, showSearchPage } from "config"
import { AmplitudeEventMap } from "lib/amplitude/events"
import { useStaffAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setInboxNotification } from "reducers/app"
import { selectMeetingKinds } from "reducers/booking"
import { selectConversations } from "reducers/conversations/conversations.reducer"

import LogoutDialog from "./components/LogoutDialog"
import CreateConversation from "components/CreateConversation/CreateConversation"

import styles from "./Navigation.module.scss"

interface HasAccessToBookingsArgs {
  roles: Role[]
  bookingTypes: BookingType[]
}

const hasAccessToBookings = ({ roles, bookingTypes }: HasAccessToBookingsArgs): boolean => {
  if (roles.some(role => role.startsWith("booking_coordinator"))) {
    return true
  }

  return Boolean(bookingTypes?.find(bookingType => bookingType.metadata && roles.includes(bookingType.metadata.role)))
}

const Navigation = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const inboxNotification = useAppSelector(state => state.app.inboxNotification)
  const activeInboxTab = useAppSelector(state => state.app.activeInboxTab)
  const isInboxPanelOpen = useAppSelector(state => state.app.isInboxPanelOpen)
  const { active, snoozed } = useAppSelector(selectConversations)
  const { defaultRole, roles } = useAppSelector(state => state.auth)
  const { bookingTypes } = useAppSelector(selectMeetingKinds)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [toggleAddConv, setToggleAddConv] = useState(false)
  const beamerRef = useRef<HTMLLIElement>(null)

  const logAmplitude = useStaffAmplitudeLogger()

  const showBulkMessagesTab = useMemo(() => {
    if (featureFlags.has("bulk_messages")) {
      if (roles.includes("cherry_pick")) {
        return true
      }
    }
    return false
  }, [roles])

  useEffect(() => {
    const button = beamerRef.current
    const handleClick = () => logAmplitude("home.nav_bar.whats_new")
    if (featureFlags.has("beamer")) {
      button?.classList.add("beamerTrigger")
      button?.addEventListener("click", handleClick, true)
    }
    return () => {
      button?.classList.remove("beamerTrigger")
      button?.removeEventListener("click", handleClick, true)
    }
  }, [logAmplitude])

  const toggleAddConvDialog = (value: boolean) => {
    // if set to true we send amplitude event
    value && logAmplitude("home.nav_bar.create_conversation")
    setToggleAddConv(value)
  }

  const openLogoutDialog = () => {
    logAmplitude("home.nav_bar.log_out")
    setLogoutOpen(true)
  }

  const changeTab = (path: string, amplitudeEventType: keyof AmplitudeEventMap) => {
    logAmplitude(amplitudeEventType)
    navigate(path)
    if (path === "/" && activeInboxTab === "inbox" && isInboxPanelOpen) dispatch(setInboxNotification(undefined))
  }

  return (
    <div className={styles.container}>
      <SidebarMenu title={intl.formatMessage({ id: "login.title" })}>
        <SidebarMenu.SubNav>
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.inbox" })}
            Icon={ICONS.Inbox}
            onClick={() => changeTab("/", "home.nav_bar.inbox")}
            isActive={location.pathname === "/"}
            showNotification={inboxNotification}
          />
          {featureFlags.has("booking") && hasAccessToBookings({ roles, bookingTypes }) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.booking" })}
              Icon={ICONS.CalendarToday}
              onClick={() => changeTab("/booking", "home.nav_bar.booking")}
              isActive={location.pathname === "/booking"}
            />
          )}
          {!!showBulkMessagesTab && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "bulk_messages.header" })}
              Icon={ICONS.Mail}
              onClick={() => changeTab("/bulk", "home.nav_bar.bulk_messages")}
              isActive={location.pathname === "/bulk"}
            />
          )}
        </SidebarMenu.SubNav>

        <SidebarMenu.SubNav>
          {featureFlags.has("create_conversation") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.create.conversation" })}
              Icon={ICONS.Add}
              onClick={() => toggleAddConvDialog(!toggleAddConv)}
              isActive={toggleAddConv}
            />
          )}
          {showSearchPage(defaultRole) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.search" })}
              Icon={ICONS.Search}
              onClick={() => changeTab("/search", "home.nav_bar.search")}
              isActive={location.pathname === "/search"}
            />
          )}
          {featureFlags.has("sip") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.dial" })}
              Icon={ICONS.Dialpad}
              onClick={() => changeTab("/dial", "home.nav_bar.call_number")}
              isActive={location.pathname === "/dial"}
            />
          )}
          {featureFlags.has("phrases") && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "phrases.toolbar.phrases.label" })}
              Icon={ICONS.TextSnippet}
              onClick={() => changeTab("/phrases", "home.nav_bar.phrases")}
              isActive={location.pathname === "/phrases"}
            />
          )}
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.settings" })}
            Icon={ICONS.Settings}
            onClick={() => changeTab("/settings", "home.nav_bar.settings")}
            isActive={location.pathname === "/settings"}
          />
          {showHelpPage(defaultRole) && (
            <SidebarMenu.MenuItem
              label={intl.formatMessage({ id: "label.help" })}
              Icon={ICONS.Info}
              onClick={() => changeTab("/help", "home.nav_bar.faq")}
              isActive={location.pathname === "/help"}
            />
          )}
          {featureFlags.has("beamer") && (
            <SidebarMenu.MenuItem
              menuItemRef={beamerRef}
              label={intl.formatMessage({ id: "label.news" })}
              Icon={ICONS.StarOutline}
            />
          )}
          <SidebarMenu.MenuItem
            label={intl.formatMessage({ id: "label.logout" })}
            Icon={ICONS.Login}
            onClick={openLogoutDialog}
          />
        </SidebarMenu.SubNav>
      </SidebarMenu>

      {featureFlags.has("create_conversation") && (
        <CreateConversation show={toggleAddConv} hide={() => setToggleAddConv(false)} />
      )}

      <LogoutDialog
        isOpen={logoutOpen}
        logout={() => dispatch(logout())}
        onClose={() => setLogoutOpen(false)}
        assigned={active.length}
        snoozed={snoozed.length}
      />
    </div>
  )
}

export default Navigation
