import { useIntl } from "react-intl"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { Button, ICONS, StaffLabelTextXS } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { searchConfig } from "config"

import ControlledTextInput from "./ControlledTextInput"
import { ChildProfileData } from "./types"

import styles from "./AddChild.module.scss"

interface AddChildProps {
  addProfile: (data: ChildProfileData) => void
  error?: AppError
  close: () => void
}

const AddChild = ({ addProfile, error, close }: AddChildProps): JSX.Element => {
  const intl = useIntl()
  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          name: "",
          pnr: ""
        }}
        onSubmit={values => {
          addProfile({
            name: values.name,
            pnr: values.pnr
          })
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(intl.formatMessage({ id: "error.required" })),
          pnr: Yup.string().required(intl.formatMessage({ id: "error.required" }))
        })}>
        {({ setFieldValue }) => (
          <Form>
            <div className={styles.header}>
              <ICONS.Child className={styles.profileIcon} />
              <StaffLabelTextXS>{intl.formatMessage({ id: "profile.add" })}</StaffLabelTextXS>
            </div>

            <div className={styles.inputs}>
              <ControlledTextInput
                name="name"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.name" })}
                onChange={(value: string) => setFieldValue("name", value)}
              />
              <ControlledTextInput
                name="pnr"
                variant="onSurface"
                label={intl.formatMessage({ id: "profile.add.label.pnr" })}
                caption={searchConfig.format || intl.formatMessage({ id: "search.pnr.placeholder" })}
                onChange={(value: string) => setFieldValue("pnr", value)}
                error={error?.show && error?.api === "addPatientProfile" ? error.message : undefined}
              />
            </div>

            <div className={styles.buttonContainer}>
              <Button variant="secondary" color="primary" onPress={close} small fullWidth>
                {intl.formatMessage({ id: "btn.cancel" })}
              </Button>
              <Button variant="primary" color="primary" type="submit" small fullWidth>
                {intl.formatMessage({ id: "btn.add" })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddChild
