import { Clinic, CustomerMetadata, MetaData } from "@doktor-se/bones-ui/dist/web-shared/types"

export const getConversationClinic = ({
  clinics,
  metadata
}: {
  clinics?: Clinic[]
  metadata: { platform?: MetaData; customer?: CustomerMetadata }
}) => {
  const metadataClinic = clinics?.find(clinic => clinic.id === metadata.platform?.clinic)
  const customerMetadataClinic = clinics?.find(clinic => clinic.id === metadata.customer?.listedAtClinicId)
  return metadataClinic || customerMetadataClinic
}

export const metersToKmString = (distance: number): string => `${Number(distance / 1000).toFixed(1)} km`

export const genderFromPnr = (pnr: string) => (+pnr.charAt(pnr.length - 2) % 2 === 0 ? "f" : "m")
