import { useMemo } from "react"
import { IntlShape } from "react-intl"

import { FieldType, JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"

export const stringArrayEquals = (codesArr1: string[], codesArr2: string[]): boolean => {
  if (codesArr1.length !== codesArr2.length) {
    return false
  }

  const sortCodesArray = (arr: string[]) => [...arr].sort((a, b) => parseFloat(a) - parseFloat(b))

  const sortedArray1 = sortCodesArray(codesArr1)
  const sortedArray2 = sortCodesArray(codesArr2)

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false
    }
  }

  return true
}

export const useLocalizeSectionNames = (intl: IntlShape) => {
  return useMemo(() => {
    if (intl) {
      return Object.entries(intl.messages)
        .filter(([key, _]) => key.startsWith("journal_note.section."))
        .sort()
        .map(
          ([key, value]) =>
            ({
              title: value,
              body: "",
              type: key.endsWith("checkbox")
                ? FieldType.checkbox
                : key.endsWith("radio_btn")
                  ? FieldType.radio_btn
                  : FieldType.textarea
            }) as JournalSection
        )
    } else {
      return [] as JournalSection[]
    }
  }, [intl])
}
