import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"

import classnames from "classnames"

import { ICONS } from "@doktor-se/bones-ui"

import { featureFlags } from "config"
import { AmplitudeEventMap } from "lib/amplitude/events"
import { useStaffAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppSelector } from "lib/hooks"

import NavigationHeader from "./components/Header"
import LogoutDialog from "./components/LogoutDialog"
import MenuItem from "./components/MenuItem"
import SubMenuItem from "./components/SubMenuItem"
import NavigationUserInfo from "./components/UserInfo"
import CreateConversation from "components/CreateConversation/CreateConversation"

import styles from "./Navigation.module.scss"

const isRouteOrSubActive = (currentPath: string, ...routes: string[]) => routes.includes(currentPath)

const Navigation = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [toggleAddConv, setToggleAddConv] = useState(false)
  const beamerRef = useRef<HTMLButtonElement>(null)
  const { roles } = useAppSelector(state => state.auth)

  const [lastInboxRoute, setLastInboxRoute] = useState("/")

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/overview") {
      setLastInboxRoute(location.pathname)
    }
  }, [location.pathname])

  const createErrandRef = useRef<HTMLButtonElement>(null)
  const [offset, setOffset] = useState({
    left: 0,
    bottom: 0
  })

  const calculateOffset = () => {
    const { bottom } = createErrandRef.current!.getBoundingClientRect()
    setOffset({ left: 145, bottom: bottom + 145 })
  }

  const logAmplitude = useStaffAmplitudeLogger()

  const toggleAddConvDialog = (value: boolean) => {
    calculateOffset()
    // if set to true we send amplitude event
    value && logAmplitude("home.nav_bar.create_conversation")
    setToggleAddConv(value)
  }

  const showBulkMessagesTab = useMemo(() => {
    if (featureFlags.has("bulk_messages")) {
      if (roles.includes("cherry_pick")) {
        return true
      }
    }
    return false
  }, [roles])

  const openLogoutDialog = () => {
    logAmplitude("home.nav_bar.log_out")
    setLogoutOpen(true)
  }

  const toggleFreshdeskChat = () => {
    logAmplitude("home.nav_bar.support_chat")
    const fcWidget = (window as any).fcWidget
    if (!!fcWidget) {
      if (fcWidget.isOpen()) {
        fcWidget.close()
      } else {
        fcWidget.open()
      }
    }
  }

  useEffect(() => {
    const button = beamerRef.current
    const handleClick = () => logAmplitude("home.nav_bar.whats_new")
    if (featureFlags.has("beamer")) {
      button?.classList.add("beamerTrigger")
      button?.addEventListener("click", handleClick, true)
    }
    return () => {
      button?.classList.remove("beamerTrigger")
      button?.removeEventListener("click", handleClick, true)
    }
  }, [logAmplitude])

  const changePage = (path: string, amplitudeEventType: keyof AmplitudeEventMap) => {
    logAmplitude(amplitudeEventType)
    navigate(path)
  }

  const toggleCollapse = () => setCollapsed(!collapsed)

  const changeInboxPage = () => {
    // if we are in one of the index pages, we navigate to clicked url
    if (location.pathname === "/" || location.pathname === "/overview") {
      changePage("/", "home.nav_bar.inbox")
    } else {
      changePage(lastInboxRoute, "home.nav_bar.inbox")
    }
  }

  return (
    <div className={classnames(styles.navigation, { [styles.expanded]: !collapsed, [styles.collapsed]: collapsed })}>
      <NavigationHeader collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className={styles.sections}>
        <div className={styles.section}>
          <MenuItem
            collapsed={collapsed}
            active={isRouteOrSubActive(location.pathname, "/", "/overview")}
            onClick={() => changeInboxPage()}
            Icon={ICONS.Inbox}
            label={intl.formatMessage({ id: "label.inbox" })}>
            <SubMenuItem
              active={location.pathname === "/overview"}
              onClick={() => changePage("/overview", "home.nav_bar.overview")}
              label={intl.formatMessage({ id: "label.overview" })}
            />
          </MenuItem>
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/paused"}
            onClick={() => changePage("/paused", "home.nav_bar.paused")}
            Icon={ICONS.Snooze}
            label={intl.formatMessage({ id: "label.paused" })}
          />
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/booking"}
            onClick={() => changePage("/booking", "home.nav_bar.booking")}
            Icon={ICONS.CalendarToday}
            label={intl.formatMessage({ id: "label.booking" })}
          />
          {!!showBulkMessagesTab && (
            <MenuItem
              collapsed={collapsed}
              active={location.pathname === "/bulk"}
              onClick={() => changePage("/bulk", "home.nav_bar.bulk_messages")}
              Icon={ICONS.Mail}
              label={intl.formatMessage({ id: "bulk_messages.header" })}
            />
          )}
        </div>
        <div className={styles.section}>
          <MenuItem
            collapsed={collapsed}
            menuItemRef={createErrandRef}
            onClick={() => toggleAddConvDialog(!toggleAddConv)}
            Icon={ICONS.Add}
            label={intl.formatMessage({ id: "label.create_errand" })}
          />
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/search"}
            onClick={() => changePage("/search", "home.nav_bar.search")}
            Icon={ICONS.Search}
            label={intl.formatMessage({ id: "label.search_errand" })}
          />
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/dial"}
            onClick={() => changePage("/dial", "home.nav_bar.call_number")}
            Icon={ICONS.Dialpad}
            label={intl.formatMessage({ id: "label.call_number" })}
          />
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/phrases"}
            onClick={() => changePage("/phrases", "home.nav_bar.phrases")}
            Icon={ICONS.TextSnippet}
            label={intl.formatMessage({ id: "phrases.toolbar.phrases.label" })}
          />
        </div>
        <div>
          <MenuItem
            collapsed={collapsed}
            onClick={toggleFreshdeskChat}
            Icon={ICONS.Chat}
            label={intl.formatMessage({ id: "label.support_chat" })}
          />
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/settings"}
            onClick={() => changePage("/settings", "home.nav_bar.settings")}
            Icon={ICONS.Settings}
            label={intl.formatMessage({ id: "label.settings" })}
          />
          {featureFlags.has("beamer") && (
            <MenuItem
              collapsed={collapsed}
              menuItemRef={beamerRef}
              onClick={() => {}}
              Icon={ICONS.StarOutline}
              label={intl.formatMessage({ id: "label.news" })}
            />
          )}
          <MenuItem
            collapsed={collapsed}
            active={location.pathname === "/help"}
            onClick={() => changePage("/help", "home.nav_bar.faq")}
            Icon={ICONS.Info}
            label={intl.formatMessage({ id: "label.help" })}
          />

          <NavigationUserInfo collapsed={collapsed} />

          <MenuItem
            collapsed={collapsed}
            onClick={openLogoutDialog}
            Icon={ICONS.Login}
            label={intl.formatMessage({ id: "label.logout" })}
            additionalButtonStyles={styles.logoutButton}
          />
        </div>
      </div>
      {featureFlags.has("create_conversation") && (
        <CreateConversation
          show={toggleAddConv}
          hide={() => setToggleAddConv(false)}
          leftOffset={offset.left}
          bottomOffset={offset.bottom}
        />
      )}
      <LogoutDialog isOpen={logoutOpen} onClose={() => setLogoutOpen(false)} />
    </div>
  )
}

export default Navigation
