import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffBodyTextS, StaffBodyTextXS, StaffBodyTextXXS } from "@doktor-se/bones-ui"
import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category, ConversationHistory } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic } from "config"

import styles from "./History.module.scss"

interface HistoryProps {
  categories: Record<number, Category>
  history?: ConversationHistory[]
}

const History = React.memo(({ history, categories }: HistoryProps) => {
  const intl = useIntl()

  const mappedHistory = useMemo(
    () =>
      history?.map(h => ({
        ...h,
        created: h.created
          ? `${intl.formatDate(h.created, { day: "2-digit", month: "2-digit", year: "numeric" })} ${intl.formatTime(
              h.created
            )}`
          : ""
      })),
    [history, intl]
  )
  const categorySymptomBubbleStyle = (category: Category | undefined) => {
    if (category && !!category.metadata.conversationMetaInit?.premiumHealth) {
      return styles.premiumHealth
    }
    if (category && !!category.metadata.conversationMetaInit?.revisit) {
      return styles.revisit
    }
    return undefined
  }

  return (
    <div>
      <StaffBodyTextS className={styles.heading} margin={{ bottom: "16px" }}>
        {intl.formatMessage({ id: "patient.info.history" })}
      </StaffBodyTextS>

      <div className={styles.body}>
        {mappedHistory ? (
          mappedHistory.map(conv => (
            <div className={styles.row} key={conv.id}>
              <SymptomBubble
                className={categorySymptomBubbleStyle(categories[conv.categoryId])}
                symptom={categories[conv.categoryId]}
                appStatic={appStatic}
                cssFilter={categorySymptomBubbleStyle(categories[conv.categoryId]) && "brightness(0);"}
              />
              <div>
                <StaffBodyTextXS margin={{ left: "12px" }} className={styles.categoryName}>
                  {categories[conv.categoryId].name}
                </StaffBodyTextXS>
                <StaffBodyTextXS margin={{ left: "12px" }} className={styles.time}>
                  {conv.created ? conv.created : intl.formatMessage({ id: "label.unknown" })}
                </StaffBodyTextXS>
              </div>
            </div>
          ))
        ) : (
          <StaffBodyTextXXS>–</StaffBodyTextXXS>
        )}
      </div>
    </div>
  )
})

export default History
