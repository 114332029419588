import { useMemo } from "react"
import { useIntl } from "react-intl"

import { useAccessRights, usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { updatePatientComment } from "api"
import { featureFlags, webdocFeatureEnabled } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import OpenPatientInTakeCare from "pages/patientInfo/components/profiles/components/OpenPatientInTakeCare/OpenPatientInTakeCare"
import OpenPatientInWebdoc from "pages/patientInfo/components/profiles/components/OpenPatientInWebdoc/OpenPatientInWebdoc"

import Comment from "./Comment"
import PatientInfoRow from "./PatientInfoRow"
import PatientName from "./PatientName"

import styles from "./PatientDetails.module.scss"

interface PatientDetailsProps {
  conversation: AssignedConversation
}

const PatientDetails = ({ conversation }: PatientDetailsProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { account, profile } = conversation
  const prevConversationId = usePrevious(conversation?.id)
  const hasAccessRights = useAccessRights(account?.accessRights, featureFlags.has("bypass_access_rights"))
  const defaultRole = useAppSelector(state => state.auth.defaultRole)

  const activePnr = useMemo(() => {
    if (!!profile) return profile.data.securityNumber
    return account.pnr
  }, [account.pnr, profile])

  const takeCareIntegrationEnabled = featureFlags.has("takecare")

  return (
    <>
      <PatientName
        displayName={conversation.account.displayName}
        prevConversationId={prevConversationId}
        profile={profile}
        conversation={conversation}
      />

      {webdocFeatureEnabled(defaultRole) && activePnr && <OpenPatientInWebdoc patientPnr={activePnr} />}

      {takeCareIntegrationEnabled && activePnr && <OpenPatientInTakeCare patientPnr={activePnr} />}

      <div className={styles.divider} style={{ marginTop: "8px" }} />

      {hasAccessRights("pnr", "read") && (
        <PatientInfoRow key={"pnr"} label={intl.formatMessage({ id: "patient.info.pnr" })} data={activePnr} />
      )}
      {hasAccessRights("phone", "read") && (
        <PatientInfoRow key={"phone"} label={intl.formatMessage({ id: "patient.info.phone" })} data={account.phone} />
      )}
      {hasAccessRights("email", "read") && (
        <PatientInfoRow key={"email"} label={intl.formatMessage({ id: "patient.info.email" })} data={account.email} />
      )}

      <div className={styles.divider} />

      {!featureFlags.has("hide_patient_comments") && (
        <Comment
          key={"comment"}
          comment={account?.comment}
          updatePatientComment={(id, c) => dispatch(updatePatientComment(id, c))}
          conversation={conversation}
          prevConversationId={prevConversationId}
        />
      )}
    </>
  )
}

export default PatientDetails
