import { useIntl } from "react-intl"

import { Button, Dialog, ICONS, StaffHeading3 } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { QueueConversation } from "../../../../../types"

import styles from "./ConfirmDialog.module.scss"

interface ConfirmDialogProps {
  isOpen: boolean
  offset?: { top?: string; right?: string; bottom?: string; left?: string }
  message: string
  title: string
  conversation?: QueueConversation | AssignedConversation
  onCancel?: () => void
  onConfirm: () => void
  confirmText: string
}

const ConfirmDialog = ({
  isOpen,
  offset,
  title,
  message,
  conversation,
  onCancel,
  onConfirm,
  confirmText
}: ConfirmDialogProps) => {
  const intl = useIntl()
  return (
    <Dialog
      isOpen={isOpen}
      offset={offset}
      onClose={onCancel || onConfirm}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <ICONS.WarningCircle width={40} height={40} />
      <StaffHeading3 margin={{ bottom: "var(--size-200)" }} className={styles.header}>
        {title}
      </StaffHeading3>
      {conversation && (
        <>
          <div className={styles.displayName}>{conversation.account.displayName}</div>
          <div className={styles.pnr}>{conversation.account.pnr}</div>
        </>
      )}

      <div className={styles.contentContainer}>{message}</div>
      <div className={styles.buttonContainer}>
        <Button color="primary" variant="primary" onPress={onConfirm} fullWidth>
          {confirmText}
        </Button>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
