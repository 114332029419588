import { useCallback, useEffect, useMemo, useState } from "react"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import { ThunkAction, ThunkDispatch } from "redux-thunk"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"
import { groupCategories } from "lib/format"
import { AppAction } from "reducers"
import { RootState } from "reducers/init"

export const useSubcategories = (categories: Category[]): { [categoryId: number]: GroupedCategories[] } => {
  return useMemo(
    () =>
      categories.reduce(
        (acc, category) => {
          const children = categories.filter(c => c.parentId === category.id)
          if (!!children.length) acc[category.id] = groupCategories(children)
          return acc
        },
        {} as { [category: number]: GroupedCategories[] }
      ),
    [categories]
  )
}

export const useStaffById = (staffId?: string | null) => {
  const staff = useAppSelector(state => state.users.staff)
  return useMemo(() => staff.find(person => person.id === staffId), [staff, staffId])
}

export type ReduxDispatch = ThunkDispatch<RootState, any, AppAction>

export const useAppDispatch = (): ReduxDispatch => {
  return useDispatch<ReduxDispatch>()
}

export type Thunk<Result = void> = ThunkAction<Result, RootState, any, AppAction>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useOnlineStatus = () => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine)

  const handleOnline = useCallback(() => {
    setOnlineStatus(true)
  }, [])

  const handleOffline = useCallback(() => {
    setOnlineStatus(false)
  }, [])

  useEffect(() => {
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [handleOffline, handleOnline])

  return useMemo(() => onlineStatus, [onlineStatus])
}

// this is a custom hook that allows to call stuff when user goes online from offline
export const useCallbackWhenOnlineAgain = (callback: Function) => {
  const onlineStatus = useOnlineStatus()
  const prevOnlineStatus = usePrevious(onlineStatus)
  useEffect(() => {
    if (onlineStatus && onlineStatus !== prevOnlineStatus) {
      callback()
    }
  }, [callback, onlineStatus, prevOnlineStatus])
}

export const useCarealotV2 = () => {
  const user = useAppSelector(state => state.auth.user)
  return useMemo(() => {
    const isEnabled = featureFlags.has("carealot2")

    const isClinicStaff = !!user?.data?.clinic

    return isEnabled && isClinicStaff
  }, [user])
}
