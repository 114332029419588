import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { LanguageCode, OpeningHour, User } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appLanguages } from "config"
import { loginUser, unauthorize } from "reducers/auth"
import { addMessage } from "reducers/conversations/conversations.reducer"

import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

export interface AppState {
  bannerState?: {
    meta?: {
      retry?: number
    }
    type: "stop" | "close" | "reconnect"
  }
  enterState: boolean
  isTyping: boolean
  typingTimeout?: ReturnType<typeof setTimeout>
  inboxNotification?: boolean
  showingWelcomeMessage: boolean
  isVideoBackgroundActive: boolean
  ringtoneVolume: number
  language: LanguageCode
  user: User
  isReadyForAssignment: boolean
  queueOpeningHours: OpeningHour[]
  calendarScrollTime: string
  isQueuePingActive: boolean
  isNewConversationInInboxNotificationActive: boolean
  showDialNumber: boolean
  dialNumberOffset: Offset
  isQueuePanelOpen: boolean
  isInboxPanelOpen: boolean
  isDetailsPanelOpen: boolean
  activeInboxTab: "inbox" | "waiting"
  overviewPageFilter: "MY_ERRANDS_FILTER" | "ONGOING_ERRANDS_FILTER" | string
}

const initialState: AppState = {
  bannerState: undefined,
  enterState: false,
  isTyping: false,
  typingTimeout: undefined,
  inboxNotification: undefined,
  showingWelcomeMessage: false,
  isVideoBackgroundActive: false,
  ringtoneVolume: 0.4,
  language: appLanguages[0]?.code,
  user: {
    id: ""
  },
  isReadyForAssignment: false,
  queueOpeningHours: [],
  calendarScrollTime: "07:00",
  isQueuePingActive: false,
  isNewConversationInInboxNotificationActive: false,
  showDialNumber: false,
  dialNumberOffset: { left: 0, top: 0 },
  isQueuePanelOpen: false,
  isInboxPanelOpen: true,
  isDetailsPanelOpen: true,
  activeInboxTab: "inbox",
  overviewPageFilter: "MY_ERRANDS_FILTER"
}

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    enterSend(state) {
      state.enterState = !state.enterState
    },
    typingMessage(state, action: PayloadAction<boolean>) {
      state.isTyping = action.payload
    },
    setTypingTimeout(state, action: PayloadAction<ReturnType<typeof setTimeout>>) {
      state.typingTimeout = action.payload
    },
    setInboxNotification(state, action: PayloadAction<boolean | undefined>) {
      state.inboxNotification = action.payload
    },
    toggleWelcomeMessage(state, action: PayloadAction<boolean>) {
      state.showingWelcomeMessage = action.payload
    },
    toggleVideoBackground(state, action: PayloadAction<boolean>) {
      state.isVideoBackgroundActive = action.payload
    },
    toggleQueuePing(state, action: PayloadAction<boolean>) {
      state.isQueuePingActive = action.payload
    },
    toggleNewConversationInInboxNotification(state, action: PayloadAction<boolean>) {
      state.isNewConversationInInboxNotificationActive = action.payload
    },
    ringtoneVolume(state, action: PayloadAction<number>) {
      state.ringtoneVolume = action.payload
    },
    changeLanguage(state, action: PayloadAction<LanguageCode>) {
      state.language = action.payload
    },
    setIsReadyForAssignment(state, action: PayloadAction<boolean>) {
      state.isReadyForAssignment = action.payload
    },
    setBanner(
      state,
      action: PayloadAction<
        | {
            meta?: {
              retry?: number
            }
            type: "stop" | "close" | "reconnect"
          }
        | undefined
      >
    ) {
      state.bannerState = action.payload
    },
    loadQueueOpeningHours(state, action: PayloadAction<OpeningHour[]>) {
      state.queueOpeningHours = action.payload
    },
    queueOpeningHourUpdated(state, action: PayloadAction<OpeningHour>) {
      state.queueOpeningHours = state.queueOpeningHours.map(openingHour => {
        if (openingHour.id === action.payload.id) return action.payload
        return openingHour
      })
    },
    setCalendarScrollTime(state, action: PayloadAction<string>) {
      state.calendarScrollTime = action.payload
    },
    setShowDialNumber(state, action: PayloadAction<boolean>) {
      state.showDialNumber = action.payload
    },
    setDialNumberOffset(state, action: PayloadAction<Offset>) {
      state.dialNumberOffset = action.payload
    },
    setQueuePanelState(state, action: PayloadAction<boolean>) {
      state.isQueuePanelOpen = action.payload
    },
    toggleInboxPanelState(state) {
      state.isInboxPanelOpen = !state.isInboxPanelOpen
    },
    setDetailsPanelState(state, action: PayloadAction<boolean>) {
      state.isDetailsPanelOpen = action.payload
    },
    setActiveInboxTab(state, action: PayloadAction<"inbox" | "waiting">) {
      state.activeInboxTab = action.payload
    },
    setOverviewPageFilter(state, action: PayloadAction<"MY_ERRANDS_FILTER" | "ONGOING_ERRANDS_FILTER" | string>) {
      state.overviewPageFilter = action.payload
    }
  },

  extraReducers: builder =>
    builder
      .addCase(loginUser, (state, action) => {
        state.user.id = action.payload.id
      })
      .addCase(addMessage, (state, action) => {
        state.isTyping =
          action.payload.type === "chat" && action.payload.createdById === state.user.id ? state.isTyping : false
      })
      .addCase(unauthorize, state => {
        state.overviewPageFilter = "MY_ERRANDS_FILTER"
      })
})

export const {
  setBanner,
  queueOpeningHourUpdated,
  loadQueueOpeningHours,
  enterSend,
  typingMessage,
  setTypingTimeout,
  setInboxNotification,
  toggleWelcomeMessage,
  toggleVideoBackground,
  toggleQueuePing,
  toggleNewConversationInInboxNotification,
  ringtoneVolume,
  changeLanguage,
  setIsReadyForAssignment,
  setCalendarScrollTime,
  setShowDialNumber,
  setDialNumberOffset,
  setQueuePanelState,
  toggleInboxPanelState,
  setDetailsPanelState,
  setActiveInboxTab,
  setOverviewPageFilter
} = app.actions

export type AppSliceAction = ObjectFunctionReturnTypes<typeof app.actions>

export default app.reducer
