import { useIntl } from "react-intl"

import classNames from "classnames"
import dayjs from "dayjs"

import { ICONS, StaffBodyTextXS } from "@doktor-se/bones-ui"
import { useTimeToNow } from "@doktor-se/bones-ui/dist/web-shared/hooks"

import styles from "./Cells.module.scss"

interface NameCellProps {
  name: string
  isChild: boolean
}

export const NameCell = ({ name, isChild }: NameCellProps) => (
  <div className={styles.nameCell}>
    {isChild && <ICONS.Child />}
    {name}
  </div>
)

interface DateTimePillCellProps {
  value: string | number
  postponed?: boolean
}

export const DateTimePillCell = ({ value, postponed = false }: DateTimePillCellProps) => {
  const intl = useIntl()
  const time = useTimeToNow(value)
  if (postponed) {
    const postponedUntil = () => {
      return dayjs(value).calendar(undefined, {
        sameDay: `HH:mm [${intl.formatMessage({ id: "unassign.postpone.today" })}]`,
        nextDay: `HH:mm [${intl.formatMessage({ id: "unassign.postpone.tomorrow" })}]`
      })
    }
    return (
      <div className={styles.dateCell}>
        <StaffBodyTextXS>{postponedUntil()}</StaffBodyTextXS>
      </div>
    )
  }

  return (
    <div className={styles.dateCell}>
      <StaffBodyTextXS>
        {time.date
          ? intl.formatDate(time.date, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : time.timeLeft}
      </StaffBodyTextXS>
    </div>
  )
}

interface BooleanCellProps {
  value: boolean
}

export const BooleanCell = ({ value }: BooleanCellProps) => {
  const intl = useIntl()
  const label = value ? "label.yes" : "label.no"
  return (
    <div className={classNames(styles.booleanCell, { [styles.true]: value, [styles.false]: !value })}>
      {intl.formatMessage({ id: label })}
    </div>
  )
}
