import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { Button, ICONS } from "@doktor-se/bones-ui"

import { checkPatientStatus, openPatientInWebdoc as openPatientInWebdocApi, webdocRefreshToken } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import { featureFlags } from "../../../../../../config"
import AuthenticateWithWebdoc from "./AuthenticateWithWebdoc"

import styles from "./OpenPatientInWebdoc.module.scss"

interface OpenPatientInWebdocProps {
  patientPnr: string
}

const OpenPatientInWebdoc = ({ patientPnr }: OpenPatientInWebdocProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const webdocTokens = useAppSelector(state => state.auth.webdocTokens)
  const staffClinicId = useAppSelector(state => state.auth.user?.data?.clinic)
  const checkPatientInWebdoc =
    featureFlags.has("webdoc") && featureFlags.has("webdoc_journal_note_send") && !staffClinicId

  const [showReauthenticate, setShowReauthenticate] = useState(false)
  const [patientHasAccountInWebdoc, setPatientHasAccountInWebdoc] = useState<boolean>(true)

  const openPatientInWebdoc = async () => {
    if (!webdocTokens?.refreshToken || !webdocTokens?.accessToken) {
      setShowReauthenticate(true)
      return
    }

    try {
      const accessToken = await dispatch(webdocRefreshToken(webdocTokens.refreshToken))
      dispatch(
        openPatientInWebdocApi(patientPnr, accessToken, intl.formatMessage({ id: "webdoc.open.patient.information" }))
      )
    } catch (error: any) {
      if (error.status === 401 || error.details?.code === "error.jwt.invalid_token") {
        setShowReauthenticate(true)
      }
      return
    }
  }
  useEffect(() => {
    const fetchPatientStatus = async () => {
      const patientStatus = await dispatch(checkPatientStatus(patientPnr))
      setPatientHasAccountInWebdoc(!!patientStatus)
    }
    checkPatientInWebdoc && fetchPatientStatus()
  }, [checkPatientInWebdoc, dispatch, patientPnr])

  return (
    <div className={styles.container}>
      {!patientHasAccountInWebdoc && (
        <div className={styles.missing_patient_in_webdoc}>
          {intl.formatMessage({ id: "webdoc.patient_no_account.message" })}
        </div>
      )}
      <AuthenticateWithWebdoc showDialog={showReauthenticate} setShowDialog={setShowReauthenticate} />
      <Button
        variant="primary"
        color="primary"
        onPress={openPatientInWebdoc}
        Icon={<ICONS.OpenInBrowser />}
        small
        fullWidth
        isDisabled={!webdocTokens?.accessToken}>
        {intl.formatMessage({ id: "webdoc.patient.open" })}
      </Button>
    </div>
  )
}

export default OpenPatientInWebdoc
