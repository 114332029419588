import { useEffect, useMemo, useState } from "react"

import classNames from "classnames"

import { ICONS, StaffBodyTextXS } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { AssignedConversation, Profile } from "@doktor-se/bones-ui/dist/web-shared/types"

import { addPatientProfile, fetchAssignedConversation, patientProfilesFetch, setPatientProfile } from "api"
import { handleErrors } from "api/error/handler"
import { featureFlags } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { closeError } from "reducers/error"
import { patientProfilesSelectors } from "reducers/users/users.reducer"

import AddProfile from "./AddProfile"
import SelectProfile from "./SelectProfile"
import { ChildProfileData, PetProfileData, ProfileList } from "./types"

import styles from "./SetProfile.module.scss"

interface SetProfileProps {
  conversation: AssignedConversation
  patientName: string
  type?: string
  prevConversationId?: string
  error?: AppError
}

const SetProfile = ({ conversation, patientName, type, prevConversationId, error }: SetProfileProps) => {
  const dispatch = useAppDispatch()

  const patientProfiles = useAppSelector(
    state => conversation && patientProfilesSelectors.selectById(state, conversation?.patientId)
  )?.profiles

  const [show, setShow] = useState({ edit: true, new: false, select: false })

  useEffect(() => {
    dispatch(patientProfilesFetch(conversation.patientId))
  }, [conversation.patientId, dispatch])

  const profiles = useMemo(() => {
    const notSelected: ProfileList[] =
      patientProfiles?.filter(p => p.id !== conversation.profile?.id && !p.removed) || []

    if (!featureFlags.has("profile_only")) {
      return notSelected.concat(
        conversation.profile?.id && conversation.account ? [{ name: conversation.account.displayName }] : []
      )
    }
    return notSelected
  }, [conversation.account, conversation.profile?.id, patientProfiles])

  useEffect(() => {
    if (conversation && prevConversationId !== conversation.id) {
      setShow({ edit: true, new: false, select: false })
    }
  }, [conversation, prevConversationId])

  useEffect(() => {
    if (show.new) dispatch(closeError())
  }, [dispatch, show.new])

  const setProfile = async (profileId?: string) => {
    try {
      await dispatch(setPatientProfile(conversation.id, profileId))
      await dispatch(fetchAssignedConversation(conversation.id))
      setShow({ edit: true, new: false, select: false })
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

  const addProfile = (data: PetProfileData | ChildProfileData) => {
    dispatch(addPatientProfile(conversation.patientId, data))
      .then((profile: Profile) => setProfile(profile.id))
      .catch((e: any) =>
        dispatch(handleErrors({ error: e, api: "addPatientProfile", customContext: "/patients/<patientId>/profiles" }))
      )
  }

  return (
    <>
      {show.edit && (
        <div className={classNames(styles.row, { [styles.active]: show.select })}>
          <button className={styles.wrapper} onClick={() => setShow(s => ({ ...s, select: !show.select }))}>
            <div className={styles.name}>
              {type === "child" && <ICONS.Child className={styles.childIcon} />}
              <StaffBodyTextXS margin={{ left: "8px" }}>{patientName}</StaffBodyTextXS>
            </div>
            {show.select ? <ICONS.ChevronUp height={24} width={24} /> : <ICONS.ChevronDown height={24} width={24} />}
          </button>

          {show.select && (
            <SelectProfile
              profiles={profiles}
              setProfile={setProfile}
              newProfile={() => setShow({ new: true, edit: false, select: false })}
            />
          )}
        </div>
      )}

      {show.new && (
        <AddProfile
          addProfile={addProfile}
          close={() => setShow(s => ({ ...s, new: false, edit: true }))}
          error={error}
        />
      )}
    </>
  )
}

export default SetProfile
