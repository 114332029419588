import { useIntl } from "react-intl"

import { Button, Dialog, StaffBodyTextM, StaffBodyTextS } from "@doktor-se/bones-ui"

import { logout } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectConversations } from "reducers/conversations/conversations.reducer"

import { IconLogOut } from "icons"

import styles from "./LogoutDialog.module.scss"

interface LogoutDialogProps {
  isOpen: boolean
  onClose: () => void
}

const LogoutDialog = ({ isOpen, onClose }: LogoutDialogProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const { active, snoozed } = useAppSelector(selectConversations)

  return (
    <Dialog isOpen={isOpen} onClose={onClose} closeAriaLabel={intl.formatMessage({ id: "modal.close" })} fixedWidth>
      <IconLogOut />

      <StaffBodyTextM margin="var(--size-200) 0">{intl.formatMessage({ id: "warning.logout.confirm" })}</StaffBodyTextM>

      {active.length > 0 || snoozed.length > 0 ? (
        <div className={styles.warning}>
          <StaffBodyTextS>{intl.formatMessage({ id: "warning.logout.conversations" })}</StaffBodyTextS>
          <div className={styles.boxes}>
            <StaffBodyTextS>
              {`${intl.formatMessage({ id: "label.inbox" })}
            : ${active.length}`}
            </StaffBodyTextS>
            <StaffBodyTextS>
              {`${intl.formatMessage({ id: "label.snoozed" })}
              : ${snoozed.length}`}
            </StaffBodyTextS>
          </div>
        </div>
      ) : undefined}

      <Button variant="primary" color="accent" onPress={() => dispatch(logout())}>
        {intl.formatMessage({ id: "btn.logout" })}
      </Button>
    </Dialog>
  )
}

export default LogoutDialog
