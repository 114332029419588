import { useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffLabelTextS } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { useAppSelector } from "lib/hooks"
import { personalBookingsSelectors } from "reducers/booking"

import { BooleanCell, DateTimePillCell, NameCell } from "components/v2/conversationList/Cells"
import ListHeader from "components/v2/conversationList/ListHeader"
import ListRow from "components/v2/conversationList/ListRow"

interface MyBookingsListProps {
  title: string
  conversations: AssignedConversation[]
}

const MyBookingsList = ({ title, conversations }: MyBookingsListProps) => {
  const intl = useIntl()
  const bookings = useAppSelector(personalBookingsSelectors.selectAll).filter(b => b.status === "booked")
  const kinds = useAppSelector(state => state.booking.bookingTypes)
  const appLanguage = useAppSelector(state => state.app.language)
  const columns = [
    {
      name: intl.formatMessage({ id: "label.overview.pnr" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.name" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.booking_type" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.booked_time" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.listed" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medical_consent" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medication_consent" })
    }
  ]
  const list = useMemo(
    () =>
      conversations.map(conv => {
        const isChildConversation = conv.profile?.type === "child"
        const booking = bookings.find(b => b.id === conv.metadata?.bookingId)
        if (!booking) return []
        return [
          {
            key: "pnr",
            content: isChildConversation
              ? formatPnr(conv.profile!.data!.securityNumber, conv.metadata?.region)
              : formatPnr(conv.account?.pnr, conv.metadata?.region)
          },
          {
            key: "name",
            content: (
              <NameCell
                name={isChildConversation ? conv.profile!.name! : conv.account.displayName!}
                isChild={isChildConversation}
              />
            )
          },
          {
            key: "kind",
            content: kinds.find(k => k.id === booking.kindId)?.localisedNames[appLanguage]
          },
          {
            key: "start",
            content: <DateTimePillCell value={booking?.start} />
          },
          {
            key: "listed",
            content: <BooleanCell value={!!conv.customerMetadata?.listedAtClinicId} />
          },
          {
            key: "recordConsent",
            content: <BooleanCell value={true} />
          },
          {
            key: "medicationConsent",
            content: <BooleanCell value={true} />
          }
        ]
      }),
    [appLanguage, bookings, conversations, kinds]
  )

  return (
    <>
      <StaffLabelTextS style={{ marginBottom: "16px" }}>{title}</StaffLabelTextS>
      <ListHeader columns={columns} />
      {list.map(l => (
        <ListRow column={l} />
      ))}
    </>
  )
}

export default MyBookingsList
