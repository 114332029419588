import { useContext } from "react"

import classNames from "classnames"

import { StaffLabelTextXXS } from "@doktor-se/bones-ui"

import { TabsContext } from "./TabsContext"

import styles from "./Tab.module.scss"

interface TabProps {
  id: string
  title: string
  className?: string
  tabsCount?: number
  notification?: boolean
}

const Tab = ({ id, title, className, tabsCount, notification }: TabProps) => {
  const tabsContext = useContext(TabsContext)
  const changeTab = (event: React.MouseEvent<HTMLButtonElement>) => tabsContext.setActiveTab(event.currentTarget.id)
  const contentSize = tabsCount ? 100 / tabsCount + "%" : "auto"

  return (
    <button
      id={id}
      style={{ inlineSize: contentSize }}
      className={classNames(styles.tab, id === tabsContext.activeTab ? styles.active : styles.hidden, className)}
      onClick={changeTab}
      tabIndex={0}>
      <div className={styles.title}>
        <StaffLabelTextXXS>{title}</StaffLabelTextXXS>
      </div>
      {notification && <div className={styles.notification} />}
    </button>
  )
}
export default Tab
