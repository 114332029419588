import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import { ICONS } from "@doktor-se/bones-ui"
import { IconButton } from "@doktor-se/bones-ui/dist/web-shared/components"

import { assignToOtherStaffEnabled, assignToQueueEnabled, featureFlags, sipRegions } from "config"
import { AmplitudeEventMap } from "lib/amplitude/events"
import { useSelectedConversationAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import ReportBug from "components/topmenu/ReportBug/ReportBug"
import { IconUnsnooze } from "icons"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import styles from "./TopMenu.module.scss"

type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>

const getPos = (e: Event) => {
  const { height } = e.currentTarget.getBoundingClientRect()
  const x = e.clientX - 600
  return { left: x, top: height }
}

interface TopMenuProps {
  authorized: boolean
  toQueue: (offset: Offset) => void
  reassign: (offset: Offset) => void
  snoozeConversation: (offset: Offset) => void
  conversationToInbox: (offset: Offset) => void
  closeConversation: (offset: Offset) => void
  isBooking?: boolean
  startCall: (offset: Offset) => void
  waiting: boolean
  callNumber: (offset: Offset) => void
  revisits: (offset: Offset) => void
}

const TopMenu = ({
  authorized,
  toQueue,
  reassign,
  snoozeConversation,
  conversationToInbox,
  closeConversation,
  isBooking = false,
  startCall,
  waiting,
  callNumber,
  revisits
}: TopMenuProps) => {
  const intl = useIntl()
  const { roles } = useAppSelector(state => state.auth)

  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const conversation = useAppSelector(selectConversation)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const category = conversation?.categoryId ? categories[conversation.categoryId] : undefined
  const isUpcomingBooking = conversation?.metadata?.isUpcomingBooking || conversation?.state === "booked"
  const hasAssignToOtherStaff = !isUpcomingBooking && assignToOtherStaffEnabled(defaultRole)
  const hasAssignToQueue = !isUpcomingBooking && assignToQueueEnabled(defaultRole)
  const snoozeConversationFeature = waiting ? !isBooking : true
  const closeConversationFeature = !isBooking || !waiting

  const logEvent = useSelectedConversationAmplitudeLogger()

  const withAmplitudeLogger = (eventType: keyof AmplitudeEventMap, onClick: (e: Event) => any) => {
    return (e: Event) => {
      logEvent(eventType)
      onClick(e)
    }
  }

  const showAppCallButton = useMemo(() => {
    const webAppPlatform = conversation?.platforms?.find(p => p.type === "web")
    let approvedWebAppPlatform = false

    if (!!webAppPlatform) {
      const allowComputerCall =
        !webAppPlatform.deviceType &&
        !!webAppPlatform.browser &&
        /Firefox|Chrome|Edge|IE|Opera|Safari/i.test(webAppPlatform.browser)

      const allowIOSCall =
        webAppPlatform.deviceType === "mobile" &&
        !!webAppPlatform?.os &&
        /iOS/i.test(webAppPlatform.os) &&
        !!webAppPlatform.browser &&
        /Safari|Chrome/i.test(webAppPlatform.browser)

      const allowAndroidCall =
        webAppPlatform.deviceType === "mobile" &&
        !!webAppPlatform?.os &&
        /Android/i.test(webAppPlatform.os) &&
        !!webAppPlatform.browser &&
        /Firefox|Chrome/i.test(webAppPlatform.browser)

      approvedWebAppPlatform = allowComputerCall || allowIOSCall || allowAndroidCall
    }

    return (
      !waiting &&
      !conversation?.metadata?.noInappCalls &&
      (!webAppPlatform || (webAppPlatform && approvedWebAppPlatform))
    )
  }, [conversation, waiting])

  const showSipCallButton = !waiting && featureFlags.has("sip") && sipRegions.has(conversation?.metadata?.region || "")
  const showRevisitsButton = useMemo(() => {
    const disabledRoles = ["external", "external_nurse"]
    const isPremiumHealth = category?.metadata.conversationMetaInit?.premiumHealth
    return featureFlags.has("revisits") && !isPremiumHealth && !roles.some(role => disabledRoles.includes(role))
  }, [category, roles])

  return (
    <>
      {authorized && conversation?.id && (
        <div className={styles.container}>
          <div className={styles.actions}>
            <div className={styles.actionsContainer}>
              {showAppCallButton && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.call_in_app.pressed", (e: Event) => startCall(getPos(e)))}
                  Icon={ICONS.PhoneiPhone}
                  label={intl.formatMessage({ id: "label.call.app" })}
                  labelSize={14}
                />
              )}

              {showSipCallButton && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.dial_number.pressed", (e: Event) => callNumber(getPos(e)))}
                  Icon={ICONS.Dialpad}
                  label={intl.formatMessage({ id: "label.dial" })}
                  labelSize={14}
                />
              )}

              {snoozeConversationFeature && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.snooze.pressed", (e: Event) =>
                    snoozeConversation(getPos(e))
                  )}
                  Icon={ICONS.Snooze}
                  label={intl.formatMessage({ id: "label.snooze" })}
                  labelSize={14}
                />
              )}

              {waiting && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.unsnooze.pressed", (e: Event) =>
                    conversationToInbox(getPos(e))
                  )}
                  Icon={IconUnsnooze}
                  label={intl.formatMessage({ id: "action.send.inbox" })}
                  labelSize={14}
                />
              )}

              {hasAssignToOtherStaff && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.reassign.pressed", (e: Event) => reassign(getPos(e)))}
                  Icon={ICONS.Redo}
                  label={intl.formatMessage({ id: "label.reassign" })}
                  labelSize={14}
                />
              )}
              {hasAssignToQueue && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.to_queue.pressed", (e: Event) => toQueue(getPos(e)))}
                  Icon={ICONS.Undo}
                  label={intl.formatMessage({ id: "label.to.queue" })}
                  labelSize={14}
                />
              )}
              {showRevisitsButton && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.revisit.pressed", (e: Event) => revisits(getPos(e)))}
                  Icon={ICONS.CalendarAdd}
                  label={intl.formatMessage({ id: "revisits.title" })}
                  labelSize={14}
                />
              )}
            </div>

            <div className={styles.actionsContainer}>
              <ReportBug conversationId={conversation.id} buttonType="icon" />
              {closeConversationFeature && (
                <IconButton
                  onClick={withAmplitudeLogger("conversation.close.pressed", (e: Event) =>
                    closeConversation(getPos(e))
                  )}
                  Icon={ICONS.CloseErrand}
                  label={intl.formatMessage({ id: "label.close" })}
                  labelSize={14}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TopMenu
