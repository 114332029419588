import { useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { StaffLabelTextXS } from "@doktor-se/bones-ui"

import { featureFlags } from "config"
import { AmplitudeEventMap } from "lib/amplitude/events"
import { useSelectedConversationAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppSelector } from "lib/hooks"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import JournalForm from "pages/dashboard/components/PatientDetailsPanel/components/JournalForm/JournalForm"

import PatientDetails from "./PatientInfo/PatientInfo"

import styles from "./PatientInfoTabs.module.scss"

interface Tab {
  label: string
  content: React.ReactNode
  amplitudeEventName: keyof AmplitudeEventMap
}

interface TabsProps {
  tabs: Tab[]
}

const Tabs = ({ tabs }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const logAmplitude = useSelectedConversationAmplitudeLogger()

  const handleTabClick = (index: number) => {
    logAmplitude(tabs[index].amplitudeEventName)
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className={classNames(styles.tabs, { [styles.tabsActive]: activeIndex !== null })}>
      <div className={styles.tabList}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={classNames(styles.tabButton, { [styles.active]: activeIndex === index })}>
            <StaffLabelTextXS>{tab.label}</StaffLabelTextXS>
          </button>
        ))}
      </div>
      {activeIndex !== null && <div className={styles.tabContent}>{tabs[activeIndex].content}</div>}
    </div>
  )
}

const PatientInfoTabs = () => {
  const intl = useIntl()
  const conversation = useAppSelector(selectConversation)
  const activeLanguage = useAppSelector(state => state.app.language)
  const staffPNR = useAppSelector(state => state.auth.user?.pnr)
  const staffClinicId = useAppSelector(state => state.auth.user?.data?.clinic)

  const hasDoctorJournal =
    featureFlags.has("webdoc") &&
    featureFlags.has("webdoc_journal_note_send") &&
    conversation?.journalNote?.data?.sections &&
    activeLanguage === "sv" &&
    staffPNR &&
    !staffClinicId

  const tabs: Tab[] = [
    {
      label: intl.formatMessage({ id: "patientinfo.tab.info" }),
      content: <PatientDetails />,
      amplitudeEventName: "inbox.patient_info.pressed"
    }
  ]

  if (hasDoctorJournal) {
    tabs.push({
      label: intl.formatMessage({ id: "patientinfo.tab.journal" }),
      content: <JournalForm />,
      amplitudeEventName: "inbox.journal_note.pressed"
    })
  }

  return <Tabs tabs={tabs} />
}

export default PatientInfoTabs
