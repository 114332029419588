import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { featureFlags } from "config"

import AddChild from "./AddChild"
import { ChildProfileData, PetProfileData } from "./types"

interface AddProfileProps {
  addProfile: (data: ChildProfileData | PetProfileData) => void
  error?: AppError
  close: () => void
}

const AddProfile = ({ addProfile, error, close }: AddProfileProps) => {
  return <>{featureFlags.has("child") && <AddChild addProfile={addProfile} error={error} close={close} />}</>
}

export default AddProfile
