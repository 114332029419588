import { useIntl } from "react-intl"

import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { createLoadingSelector } from "api"
import { useAppSelector } from "lib/hooks"
import { selectSnoozedConversations } from "reducers/conversations/conversations.reducer"

import PausedConversationList from "./components/PausedConversationList"

import styles from "./PausedPage.module.scss"

const loadingSelector = createLoadingSelector(["ASSIGNED_CONVERSATIONS"])

const PausedPage = () => {
  const intl = useIntl()
  const loader = useAppSelector(state => loadingSelector(state))
  const snoozed = useAppSelector(selectSnoozedConversations)

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{intl.formatMessage({ id: "label.paused" })}</h1>
      {loader && <Loader type="small" />}
      {!loader && (
        <div className={styles.listContent}>
          <PausedConversationList conversations={snoozed} />
        </div>
      )}
    </div>
  )
}

export default PausedPage
