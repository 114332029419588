import { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { StaffLabelTextS } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { BooleanCell, DateTimePillCell, NameCell } from "components/v2/conversationList/Cells"
import EmptyListRow from "components/v2/conversationList/EmptyListRow"
import ListHeader from "components/v2/conversationList/ListHeader"
import ListRow, { Column } from "components/v2/conversationList/ListRow"

import { useAppDispatch } from "../../../../lib/hooks"
import { setConversation } from "../../../../reducers/selected"

interface MyErrandsListProps {
  title: string
  conversations: AssignedConversation[]
}

export interface ConversationListItem {
  conversation: AssignedConversation
  values: Column[]
}

const MyErrandsList = ({ title, conversations }: MyErrandsListProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const columns = [
    {
      name: intl.formatMessage({ id: "label.overview.pnr" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.name" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.errand_started" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.listed" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.paused" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medical_consent" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medication_consent" })
    }
  ]
  const shouldNotify = (c: AssignedConversation) => {
    return (c.lastMessage?.id && c.lastMessage.id > (c.lastSeenMessageId ?? 0)) || c.callState === "incoming"
  }

  const mapConversationListItem = useCallback((conv: AssignedConversation): ConversationListItem => {
    const isChildConversation = conv.profile?.type === "child"
    return {
      conversation: conv,
      values: [
        {
          key: "pnr",
          content: isChildConversation
            ? formatPnr(conv.profile!.data!.securityNumber, conv.metadata?.region)
            : formatPnr(conv.account?.pnr, conv.metadata?.region)
        },
        {
          key: "name",
          content: (
            <NameCell
              name={isChildConversation ? conv.profile!.name! : conv.account.displayName!}
              isChild={isChildConversation}
            />
          )
        },
        {
          key: "start",
          content: <DateTimePillCell value={conv.created} />
        },
        {
          key: "listed",
          content: <BooleanCell value={!!conv.customerMetadata?.listedAtClinicId} />
        },
        {
          key: "paused",
          content: !!conv?.snoozedUntil ? <FormattedMessage id="label.yes" /> : <FormattedMessage id="label.no" />
        },
        {
          key: "recordConsent",
          content: <BooleanCell value={true} />
        },
        {
          key: "medicationConsent",
          content: <BooleanCell value={true} />
        }
      ]
    }
  }, [])

  const list = useMemo(
    () => conversations.map(conv => mapConversationListItem(conv)),
    [conversations, mapConversationListItem]
  )
  return (
    <>
      <StaffLabelTextS style={{ marginBottom: "16px" }}>{title}</StaffLabelTextS>
      <ListHeader columns={columns} />

      {list.length ? (
        list.map(l => (
          <ListRow
            column={l.values}
            onClick={() => {
              dispatch(setConversation(l.conversation.id))
              navigate("/")
            }}
            notify={shouldNotify(l.conversation)}
          />
        ))
      ) : (
        <EmptyListRow>You have no active errands</EmptyListRow>
      )}
    </>
  )
}

export default MyErrandsList
