import { useIntl } from "react-intl"

import { StaffLabelTextM } from "@doktor-se/bones-ui"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppSelector } from "lib/hooks"
import { queueCountSelectors } from "reducers/queue/queue.reducer"

import FilterButton from "./FilterButton"

import styles from "./QueueList.module.scss"

interface QueueListProps {
  queues: Queue[]
  activeFilter: string
  onClick: (key: string) => void
}

const QueueList = ({ queues, activeFilter, onClick }: QueueListProps) => {
  const intl = useIntl()
  const appLanguage = useAppSelector(state => state.app.language)
  const queueLabel = (queue: Queue) =>
    `${queue.translations[appLanguage]} (${queueCount[queue.queueKey]?.conversationCount})`
  const queueCount = useAppSelector(queueCountSelectors.selectEntities)

  return (
    <div>
      <StaffLabelTextM style={{ marginBottom: "8px" }}>{intl.formatMessage({ id: "label.queues" })}</StaffLabelTextM>
      <div className={styles.list}>
        {queues.map(q => (
          <FilterButton
            active={activeFilter === q.queueKey}
            key={q.queueKey}
            label={queueLabel(q)}
            onClick={() => onClick(q.queueKey)}
          />
        ))}
      </div>
    </div>
  )
}

export default QueueList
