import { useIntl } from "react-intl"

import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import MyBookingsList from "./MyBookingsList"
import MyErrandsList from "./MyErrandsList"

import styles from "./AssignedConversationList.module.scss"

interface AssignedConversationListProps {
  active: AssignedConversation[]
  booked: AssignedConversation[]
}

const AssignedConversationList = ({ active, booked }: AssignedConversationListProps) => {
  const intl = useIntl()
  return (
    <>
      <div className={styles.listContainer}>
        <MyErrandsList title={intl.formatMessage({ id: "label.overview.my_errands" })} conversations={active} />
      </div>
      {booked.length > 0 && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.listContainer}>
            <MyBookingsList title={intl.formatMessage({ id: "label.overview.my_bookings" })} conversations={booked} />
          </div>
        </>
      )}
    </>
  )
}

export default AssignedConversationList
