import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { BooleanCell, DateTimePillCell, NameCell } from "components/v2/conversationList/Cells"
import EmptyListRow from "components/v2/conversationList/EmptyListRow"
import ListHeader from "components/v2/conversationList/ListHeader"
import ListRow from "components/v2/conversationList/ListRow"

interface PausedConversationListProps {
  conversations: AssignedConversation[]
}

const PausedConversationList = ({ conversations }: PausedConversationListProps) => {
  const intl = useIntl()
  const columns = [
    {
      name: intl.formatMessage({ id: "label.overview.pnr" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.name" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.errand_started" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.listed" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.paused" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medical_consent" })
    },
    {
      name: intl.formatMessage({ id: "label.overview.medication_consent" })
    }
  ]

  const list = useMemo(
    () =>
      conversations.map(conv => {
        const isChildConversation = conv.profile?.type === "child"
        return [
          {
            key: "pnr",
            content: isChildConversation
              ? formatPnr(conv.profile!.data!.securityNumber, conv.metadata?.region)
              : formatPnr(conv.account?.pnr, conv.metadata?.region)
          },
          {
            key: "name",
            content: (
              <NameCell
                name={isChildConversation ? conv.profile!.name! : conv.account.displayName!}
                isChild={isChildConversation}
              />
            )
          },
          {
            key: "start",
            content: <DateTimePillCell value={conv.created} />
          },
          {
            key: "listed",
            content: <BooleanCell value={!!conv.customerMetadata?.listedAtClinicId} />
          },
          {
            key: "paused",
            content: !!conv?.snoozedUntil ? <FormattedMessage id="label.yes" /> : <FormattedMessage id="label.no" />
          },
          {
            key: "recordConsent",
            content: <BooleanCell value={true} />
          },
          {
            key: "medicationConsent",
            content: <BooleanCell value={true} />
          }
        ]
      }),
    [conversations]
  )

  return (
    <>
      <ListHeader columns={columns} />
      {list.length ? (
        list.map(l => <ListRow column={l} />)
      ) : (
        <EmptyListRow>{intl.formatMessage({ id: "label.paused.empty" })}</EmptyListRow>
      )}
    </>
  )
}

export default PausedConversationList
