import { UAParser } from "ua-parser-js"

import { ApiError, CallStateChangedMessage, IncomingApiError } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchAssignedConversations, freshdeskCreateTicket, logout } from "api"
import { environment, featureFlags, platform } from "config"
import { Thunk } from "lib/hooks"
import { IncomingError, logError, LogErrorPayload, logErrorV2, loginError, passwordLoginError } from "reducers/error"

import { ErrorEventHandler, flagCall, flagConversation } from "./error.api"
import { customErrors } from "./expectedErrors"

export const handleFlagged =
  (flagBody: string, flagSubject: string, conversationId?: string): Thunk =>
  (dispatch, getState) => {
    const user = getState().auth.user
    const clinics = getState().clinics.clinics.entities
    const conversation = conversationId ? getState().conversations.conversations.entities[conversationId] : undefined
    const patientId = conversation?.patientId || "undefined"
    if (!user) return

    const businessUnit = user.data?.clinic ? clinics[user.data?.clinic]?.name || "missing clinic" : "digital"

    const ua = new UAParser().setUA(navigator.userAgent).getResult()

    const tags = featureFlags.has("freshdesk_business_unit_ticket_tag")
      ? [businessUnit, platform, environment, "flagged"]
      : [platform, environment, "flagged"]

    let freshdeskDescription = `User id: ${user.id} - Conversation id: ${conversationId} - Patient id: ${patientId} - Message: ${flagBody} - Device: * Browser name: ${ua.browser.name} * Browser version: ${ua.browser.version} * OS name: ${ua.os.name} * OS version: ${ua.os.version}`

    if (conversation) {
      const sessionIds = (
        conversation.messages.filter(
          message => message.type === "call" && !!message.data.sessionId
        ) as CallStateChangedMessage[]
      )
        .slice(-3)
        .map(message => message.data.sessionId)
        .reduce((acc, id, i) => {
          let accumulator = acc
          if (i === 0) accumulator = id
          if (i !== 0) accumulator = `${accumulator}, ${id}`
          return accumulator
        }, "")
      freshdeskDescription += ` - Opentok session ids: ${sessionIds}`
    }

    const freshdeskData = {
      name: user.displayName,
      email: user.email,
      subject: flagSubject,
      description: freshdeskDescription,
      tags
    }

    dispatch(freshdeskCreateTicket(freshdeskData))
    if (conversationId) {
      flagConversation(conversationId, patientId, user, flagBody, flagSubject)
    } else {
      flagCall(patientId, user, flagBody, flagSubject)
    }
  }

export const handleLoginErrors =
  (data: { error: IncomingError }, password = false): Thunk =>
  (dispatch, getState) => {
    let errorMessage: string | undefined
    errorMessage = data.error?.status?.toString()
    if (data.error.details && data.error.details.message) {
      errorMessage = data.error.details.message
    } else if (data.error.message) {
      errorMessage = data.error.message
    }
    if (data.error?.status && ![401, 403].includes(data.error.status)) {
      ErrorEventHandler(data.error, undefined, getState().auth.user)
    }
    dispatch(password ? passwordLoginError(errorMessage) : loginError(errorMessage))
  }

export const handleErrors =
  (data: {
    error: IncomingError
    customError?: boolean
    api?: string
    customMessage?: string
    customContext?: string
    hide?: boolean
  }): Thunk =>
  async (dispatch, getState) => {
    const payload: LogErrorPayload = {
      message: "",
      error: data.error,
      customError: data.customError || false,
      customMessage: data.customMessage,
      api: data.api,
      image: undefined,
      customContext: data.customContext,
      hide: data.hide || false
    }
    switch (payload.error.status) {
      case 409:
        if (!payload.customMessage && !payload.error.details?.message) {
          payload.customMessage = "error.action.conflict"
        }
        break
      default:
        break
    }

    if (payload.error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
      const [permissionsImg] = await Promise.all([
        import(`../../img/language/${getState().app.language}/permissions.png`)
      ])
      payload.image = permissionsImg.default
      payload.customMessage = "error.permissions.denied"
    }

    if (customErrors.some(e => (payload.error.details?.code ?? "").includes(e))) {
      payload.customError = true
    }

    if (payload.customMessage) {
      payload.message = payload.customMessage
    } else if (payload.error.details && payload.error.details.message) {
      payload.message = payload.error.details.message
    } else if (payload.error.message) {
      payload.message = payload.error.message
    }

    switch (payload.error.status) {
      case 401:
        dispatch(logout())
        break
      case 409:
        dispatch(logError(payload))
        if (payload.api !== "addPatientProfile" && payload.api !== "claimConversation") {
          dispatch(fetchAssignedConversations())
        }
        break
      default:
        dispatch(logError(payload))
        ErrorEventHandler(payload.error, payload.customContext, getState().auth.user)
        break
    }
  }

export const generateCustomErrorLokaliseKey = ({
  errors,
  customErrorCodes,
  lokaliseKeySuffix
}: {
  errors: IncomingApiError[]
  customErrorCodes: string[]
  lokaliseKeySuffix: string
}) => {
  const customError = errors.find(e => customErrorCodes.includes(e.code))
  const customMessageLokaliseKey = customError ? `${customError.code}.${lokaliseKeySuffix}` : undefined
  return customMessageLokaliseKey
}

export const handleErrorsV2 =
  (data: { error: ApiError; customError?: boolean; customMessageLokaliseKey?: string; hide?: boolean }): Thunk =>
  (dispatch, getState) => {
    let localisedKey = "error.message.default"

    /* 
    Add customMessageLokaliseKey in the catch of the api call 
    e.g. by using the generateCustomErrorLokaliseKey function with IncomingApiError --> errors --> error.code
    or by setting it as any lokalise key

    Example
    catch((error: ApiError) => {
      dispatch(
        handleErrorsV2({
          error,
          customMessageLokaliseKey: generateCustomErrorLokaliseKey({
            errors: error.errors,
            customErrorCodes: ["error.entity.conflict", "error.not_found", "error.forbidden"],
            lokaliseKeySuffix: "conversation.close"
          })
        })
      )
    })
    */

    if (data.customMessageLokaliseKey) {
      localisedKey = data.customMessageLokaliseKey
    }

    switch (data.error.status) {
      case 401:
        dispatch(logout())
        break
      default:
        dispatch(
          logErrorV2({
            localisedKey,
            customError: data.customError || false,
            hide: data.hide || false
          })
        )
        ErrorEventHandler(data.error, undefined, getState().auth.user)
        break
    }
  }
