import React from "react"

import classnames from "classnames"

import styles from "./SubMenuItem.module.scss"

interface SubMenuItemProps {
  label: string
  onClick: () => void
  active?: boolean
}

const SubMenuItem = ({ label, onClick, active = false }: SubMenuItemProps) => (
  <button className={classnames(styles.subMenuItem, { [styles.subMenuItemActive]: active })} onClick={onClick}>
    {label}
  </button>
)

export default SubMenuItem
