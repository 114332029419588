import { useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffHeading4 } from "@doktor-se/bones-ui"

import { featureFlags } from "config"
import { useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import History from "./components/history/History"
import PatientDetails from "./components/PatientDetails"

import styles from "./PatientInfo.module.scss"

const PatientInfo = () => {
  const intl = useIntl()
  const conversation = useAppSelector(selectConversation)
  const categories = useAppSelector(categorySelectors.selectEntities)

  const history = useMemo(() => {
    const historyList = conversation?.history || []
    if (conversation) {
      return historyList.filter(h => h.profileId === conversation?.profile?.id)
    }
    return historyList.filter(h => !h.profileId)
  }, [conversation])

  if (!conversation) return null

  return (
    <div className={styles.container}>
      <StaffHeading4 className={styles.heading}>{intl.formatMessage({ id: "patientinfo.tab.info" })}</StaffHeading4>
      <div className={styles.headingDivider}></div>
      {conversation?.id && conversation.account && (
        <>
          <PatientDetails conversation={conversation} />
          {featureFlags.has("patient_history") && <History history={history} categories={categories} />}
        </>
      )}
    </div>
  )
}

export default PatientInfo
