import { useIntl } from "react-intl"

import { useAppSelector } from "lib/hooks"
import { selectClinicConversations, selectConversation } from "reducers/conversations/conversations.reducer"
import { queueSelectors } from "reducers/queue/queue.reducer"

import FeedContainer from "./components/FeedContainer"
import PatientHeader from "./components/PatientHeader"
import TopMenuContainer from "./components/TopMenuContainer"
import { feedTexts } from "pages/dashboard/utils"

import styles from "./ConversationContainer.module.scss"

const ConversationContainer = () => {
  const intl = useIntl()
  const appLanguage = useAppSelector(state => state.app.language)
  const queues = useAppSelector(queueSelectors.selectAll)
  const texts = feedTexts(intl, queues, appLanguage)

  const conversation = useAppSelector(selectConversation)
  const { active } = useAppSelector(selectClinicConversations)
  const hasSelectedConversation = !!conversation && active.some(e => e.id === conversation.id)

  return (
    <div className={styles.container}>
      {hasSelectedConversation && (
        <>
          <TopMenuContainer conversation={conversation} conversations={active} />
          <PatientHeader conversation={conversation} />
          <FeedContainer conversation={conversation} texts={texts} />
        </>
      )}
    </div>
  )
}

export default ConversationContainer
