import { useMemo } from "react"
import { useIntl } from "react-intl"

import { StaffLabelTextM } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { createLoadingSelector } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setOverviewPageFilter } from "reducers/app"
import { selectClinicConversations } from "reducers/conversations/conversations.reducer"
import { queueSelectors } from "reducers/queue/queue.reducer"

import AssignedConversationList from "./components/AssignedConversationList"
import FilterButton from "./components/FilterButton"
import QueueConversationList from "./components/QueueConversationList"
import QueueList from "./components/QueueList"

import styles from "./OverviewPage.module.scss"

const loadingSelector = createLoadingSelector(["ASSIGNED_CONVERSATIONS", "QUEUE_COUNT"])

const OverviewPage = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const queues = useAppSelector(queueSelectors.selectAll)
  const loader = useAppSelector(state => loadingSelector(state))
  const { active, booked } = useAppSelector(selectClinicConversations)
  const activeFilter = useAppSelector(state => state.app.overviewPageFilter)

  const setActiveFilter = (filter: string) => {
    dispatch(setOverviewPageFilter(filter))
  }

  const selectedQueue = useMemo(() => queues.find(q => q.queueKey === activeFilter), [activeFilter, queues])

  const renderListContent = () => {
    if (activeFilter === "MY_ERRANDS_FILTER") {
      return <AssignedConversationList active={active} booked={booked} />
    }
    if (activeFilter === "ONGOING_ERRANDS_FILTER") {
      return ""
    }
    return <QueueConversationList selectedQueue={selectedQueue} selectedQueueKey={activeFilter} />
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{intl.formatMessage({ id: "label.inbox" })}</h1>
      {loader && <Loader type="small" />}
      {!loader && (
        <>
          <QueueList queues={queues} activeFilter={activeFilter} onClick={setActiveFilter} />
          <div>
            <StaffLabelTextM style={{ marginBottom: "8px", marginTop: "24px" }}>
              {intl.formatMessage({ id: "label.overview.active_errands" })}
            </StaffLabelTextM>
            <FilterButton
              key={"MY_ERRANDS_FILTER"}
              active={activeFilter === "MY_ERRANDS_FILTER"}
              label={intl.formatMessage({ id: "label.overview.my_errands_filter" }, { value: active.length || 0 })}
              onClick={() => setActiveFilter("MY_ERRANDS_FILTER")}
            />
          </div>
          <div className={styles.listContent}>{renderListContent()}</div>
        </>
      )}
    </div>
  )
}

export default OverviewPage
