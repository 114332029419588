import classNames from "classnames"

import { StaffLabelTextS } from "@doktor-se/bones-ui"

import styles from "./FilterButton.module.scss"

interface FilterButtonProps {
  key: string
  label: string
  onClick: (key: string) => void
  active: boolean
}

const FilterButton = ({ key, label, onClick, active }: FilterButtonProps) => (
  <button key={key} className={classNames(styles.button, { [styles.active]: active })} onClick={() => onClick(key)}>
    <StaffLabelTextS>{label}</StaffLabelTextS>
  </button>
)

export default FilterButton
