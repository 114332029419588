import { StaffLabelTextXS } from "@doktor-se/bones-ui"

import styles from "./ListHeader.module.scss"

interface ListHeaderProps {
  columns: { name: string }[]
}

const ListHeader = ({ columns }: ListHeaderProps) => {
  return (
    <div className={styles.header}>
      {columns.map(column => (
        <div className={styles.label} key={column.name}>
          <StaffLabelTextXS>{column.name}</StaffLabelTextXS>
        </div>
      ))}
    </div>
  )
}

export default ListHeader
