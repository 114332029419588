import { useEffect } from "react"

import { featureFlags, freshdeskLanguage, platform } from "config"

export const useFreshdeskChat = (hideChatButton = false) => {
  useEffect(() => {
    if (!featureFlags.has("freshdesk_chat")) return

    const fcWidget = (window as any).fcWidget

    if (!!fcWidget && !fcWidget.isInitialized()) {
      fcWidget.init({
        token: "2d72a03d-bbc2-4d47-9795-dde6813a01f4",
        host: "https://wchat.eu.freshchat.com",
        siteId: platform,
        locale: freshdeskLanguage,
        config: {
          headerProperty: {
            hideChatButton
          }
        }
      })
    }

    return () => {
      fcWidget?.destroy()
    }
  }, [hideChatButton])
}
